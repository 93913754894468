import { useMutation } from '@apollo/client';
import { getOperationName } from '@apollo/client/utilities';
import moment from 'moment-timezone';
import LayoutWithQuery from 'components/layout/LayoutWithQuery.tsx';
import { RideForm } from 'components/rides/RideForm';
import { loader } from 'graphql.macro';
import { momentFromTimeString } from 'lib/TimeUtils';
import { useTracking } from 'lib/analytics/Tracker';
import { useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { MncsPromptField } from '../generated/graphql';
import { normalizePhone } from '@onwardcare/core/lib/validation/form-validation';
import { getLBSFromKg } from '../components/rides/rideFormComponents/helpers';
import { useSessionContext } from '../contexts/session-context';
import { readIsMetric } from '../storages/is-metric-storage';

const UPDATE_RIDE_MUTATION = loader(
  '../data/mutations/UpdateRideMutation.graphql',
);
const DASHBOARD_QUERY = loader('../data/queries/DashboardQuery.graphql');
const SCHEDULE_QUERY_WITH__RIDE = loader(
  '../data/queries/ScheduleQueryWithRide.graphql',
);
const RECENT_LOCATIONS_QUERY = loader(
  '../data/queries/RecentLocationsQuery.graphql',
);
export default function EditRide() {
  const [updateError, setUpdateError] = useState(false);
  const [submitting, setSubmitting] = useState(false);

  const tracker = useTracking();
  const history = useHistory();
  const { isAdmin, session } = useSessionContext();

  useEffect(() => {
    // hardcode this so the server knows what times these are coming in as
    // the server will interpret times in this zone and convert to the ride's time
    moment.tz.setDefault('America/Los_Angeles');
    tracker.track('Edit Ride Viewed');
  }, [tracker]);

  const onRideUpdated = data => {
    const error = data.updateRide.error;
    if (error) {
      onRideUpdateError(error);
    } else {
      history.replace({ pathname: '/rides/' + data.updateRide.ride.id });
    }
  };

  const onRideUpdateError = error => {
    if (error) {
      setSubmitting(false);
      setUpdateError(
        'There was an unexpected error updating your ride. Please contact Onward at 1-800-700-4797.',
      );
    }
  };

  const rideToPersist = data => {
    const { ride, startTime, endTime, appointmentTimeTransportReason } = data;
    const isMetric = readIsMetric();

    const {
      notes,
      passengerCount,
      rideType,
      deliverySubstitution,
      startLocation,
      startLocationPhoto,
      endLocation,
      endLocationPhoto,
      riderId,
      draft,
      paymentMethodId,
      requestedStartTime: startDay,
      transportType,
      stairsCount,
      startLocationInstructions,
      endLocationInstructions,
      heightInches,
      weight: weightLbsResult,
      wheelchairOwner,
      dropoffRoomNumber,
      pickupRoomNumber,
      contactPrecautionsRequired,
      oxygenRequired,
      transportReasonComment,
      //@ts-ignore
      transportReason,
      arrivalBufferMinutes,
    } = ride;

    const weightLbs = isMetric
      ? weightLbsResult && getLBSFromKg(weightLbsResult)
      : weightLbsResult;

    const slocation = { ...startLocation };
    // We pass the photo on the root of the ride.
    delete slocation.locationPhoto;
    const elocation = { ...endLocation };
    // We pass the photo on the root of the ride.
    delete elocation.locationPhoto;

    slocation.instructions = startLocationInstructions;
    elocation.instructions = endLocationInstructions;

    const requestedStartTime = momentFromTimeString(
      startTime,
      startDay,
    ).toISOString();

    const requestedEndTime = endTime
      ? momentFromTimeString(endTime, startDay).toISOString()
      : null;

    const appointmentTime =
      appointmentTimeTransportReason?.startAppointmentTime &&
      momentFromTimeString(
        appointmentTimeTransportReason.startAppointmentTime,
        appointmentTimeTransportReason.startAppointmentDay,
      ).toISOString();

    return {
      requestedStartTime,
      requestedEndTime,
      notes,
      passengerCount: parseInt(passengerCount, 10),
      rideType,
      startLocation: slocation,
      startLocationPhoto,
      endLocation: elocation,
      endLocationPhoto,
      riderId,
      deliverySubstitution,
      draft,
      paymentMethodId,
      transportType,
      stairsCount,
      heightInches,
      weightLbs,
      wheelchairOwner,
      dropoffRoomNumber,
      pickupRoomNumber,
      contactPrecautionsRequired,
      oxygenRequired,
      transportReasonComment,
      transportReasonId: transportReason?.id,
      appointmentTime,
      arrivalBufferMinutes,
    };
  };

  let { rideId } = useParams();
  rideId = parseInt(rideId);

  const [updateRide] = useMutation(UPDATE_RIDE_MUTATION, {
    // we're using an array of strings instead of objects as this method reuses the variables,
    // which is good for recent locations
    refetchQueries: [
      getOperationName(DASHBOARD_QUERY),
      getOperationName(RECENT_LOCATIONS_QUERY),
    ],
    onCompleted: onRideUpdated,
    onError: onRideUpdateError,
  });

  const onUpdate = async data => {
    const { mncsOptions, bedsideNurse, ...rest } = data;

    tracker.track('Ride Updated');
    setSubmitting(true);

    await updateRide({
      variables: {
        ride: rideToPersist(rest),
        bedsideNurse: {
          fullName: bedsideNurse?.fullName || '',
          // sending only numbers to a BE
          phone: normalizePhone(bedsideNurse?.phone) || '',
        },
        mncsOptions,
        rideId,
      },
    });
  };

  return (
    <LayoutWithQuery
      usePadding="no"
      query={SCHEDULE_QUERY_WITH__RIDE}
      variables={{ rideId: parseInt(rideId) }}
      renderView={data => {
        const ride = data?.ride;
        if (ride) {
          const rideHasDriver = !!ride.driver;
          const rideStatusAllowsEdit = ride?.status
            ? ['draft', 'pending'].includes(ride?.status)
            : true;

          let isBidWindowOpen = false;

          if (ride.biddingWindowEndsAt) {
            isBidWindowOpen = moment
              .utc(ride.biddingWindowEndsAt)
              .isAfter(moment.utc());
          }

          const rideEditDisabled =
            ride.id &&
            (rideHasDriver || !rideStatusAllowsEdit || isBidWindowOpen);

          if (rideEditDisabled) {
            history.replace({
              pathname: `/rides/${ride.id}/editDetails`,
            });
          }
        }

        let mncsDefaultAnswers = {};
        if (data.ride?.medicalNecessityCertificationStatement?.mncsAnswers) {
          mncsDefaultAnswers = data.ride.medicalNecessityCertificationStatement.mncsAnswers?.reduce(
            (acc, curr) => {
              if (curr) {
                // @ts-ignore
                acc[curr.slug] =
                  curr.fieldType === MncsPromptField.Boolean
                    ? curr.value === 'true'
                    : curr.value;
              }

              return acc;
            },
            {},
          );
        }

        return (
          <RideForm
            riders={data?.riders}
            profile={session?.profile}
            account={session?.account}
            createError={updateError}
            onSubmit={onUpdate}
            isSubmitting={submitting}
            admin={isAdmin}
            defaultRide={{ ...data?.ride }}
            isReturnTrip={true}
            mncsDefaultAnswers={mncsDefaultAnswers}
            actionType="update"
          />
        );
      }}
    />
  );
}
