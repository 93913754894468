import React from 'react';
import { Controller } from 'react-hook-form';
import {
  FormControl,
  FormControlLabel,
  Checkbox,
} from '@material-ui/core';
import { CheckboxProps } from './types';
import { LabelWithTooltip } from 'components/LabelWithTooltip';
import useStyles from './styles';

const _FlexibleRideCheckboxComponent: React.FC<CheckboxProps> = ({
  control,
  disabled,
}) => {
  const classes = useStyles();

  return (
    <FormControl component="fieldset" className={classes.formControl}>
      <FormControlLabel
        control={
          <Controller
            name="ride.flexibleRide"
            control={control}
            render={({ onChange, value }) => (
              <Checkbox
                disabled={disabled}
                onChange={e => onChange(e.target.checked)}
                checked={Boolean(value)}
              />
            )}
          />
        }
        label={
          <LabelWithTooltip
            labelText="Book as Flexible Ride"
            tooltipText="This will schedule a ride for the pickup day selected, 
            and an SMS message will be sent to the rider on the day of pickup so 
            they may request the ride when they are ready to be picked up."
          />
        }
      />
    </FormControl>
  );
};

export const FlexibleRideCheckboxComponent = React.memo(
  _FlexibleRideCheckboxComponent,
);
