import { ApolloClient, ApolloProvider, ApolloLink } from '@apollo/client';
import { setContext } from '@apollo/client/link/context';
import { onError } from '@apollo/client/link/error';
import { InMemoryCache } from '@apollo/client/cache';
import { createUploadLink } from 'apollo-upload-client';

import { useAuth } from '../contexts/auth-context';

import ErrorHandler from '../lib/error-handler';

import ENV from '../constants/Env';

const OnwardApolloProvider = ({ children }) => {
  const auth = useAuth();

  const authLink = setContext((request, previousContext) => {
    const authHeaders = auth.getAuthRequestHeaders();
    return {
      headers: authHeaders,
    };
  });

  const errorLink = onError(({ graphQLErrors, networkError }) => {
    if (graphQLErrors) {
      graphQLErrors.forEach(graphQLError => {
        let errorMsg = '';
        if (typeof graphQLError === 'string') {
          errorMsg = `[GraphQL error]: Message: ${graphQLError}`;
        } else {
          const { message, locations, path } = graphQLError;
          errorMsg = `[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`;
        }
        ErrorHandler.captureMessage(errorMsg);
      });
    }

    if (networkError) {
      ErrorHandler.captureMessage(`[Network error]: ${networkError}`);
      if (networkError.statusCode && networkError.statusCode === 401) {
        auth.logout(apolloClient);
      }
    }
  });

  const uploadLink = createUploadLink({
    uri: `${ENV.apiUrl}/graphql`,
    credentials: 'same-origin',
  });

  const apolloClient = new ApolloClient({
    cache: new InMemoryCache(),
    link: ApolloLink.from([errorLink, authLink, uploadLink]),
  });
  return <ApolloProvider client={apolloClient}>{children}</ApolloProvider>;
};

export default OnwardApolloProvider;
