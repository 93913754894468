import { useEffect, useState } from 'react';

import { validEmail } from '@onwardcare/core/lib/validation/form-validation';

import { useHistory, useLocation } from 'react-router-dom';
import { useAuth } from '../contexts/auth-context';
import { useForm } from 'react-hook-form';
import { setLocalhostIp } from '../constants/Env';
import { deleteToken, getToken } from '../auth/onward-token';

import {
  Avatar,
  Button,
  TextField,
  Link,
  Grid,
  Box,
  Typography,
  Container,
} from '@material-ui/core';
import { Alert } from '@material-ui/lab';
import { Link as RouterLink } from 'react-router-dom';

import { makeStyles } from '@material-ui/core/styles';

import onwardPin from '../images/logo192.png';
import { useTracking } from 'lib/analytics/Tracker';
import ENV from '../constants/Env';

const { apiUrl } = ENV;

function Copyright() {
  return (
    <Typography variant="body2" color="textSecondary" align="center">
      {'Copyright © '}
      <Link color="inherit" href="https://onwardrides.com/">
        Onward Health, Inc.
      </Link>{' '}
      {new Date().getFullYear()}
      {'.'}
    </Typography>
  );
}

const useStyles = makeStyles(theme => ({
  paper: {
    marginTop: theme.spacing(8),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  avatar: {
    margin: theme.spacing(1),
    // backgroundColor: theme.palette.secondary.main,
    width: theme.spacing(8),
    height: theme.spacing(8),
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
}));

function LoginComponent({ loginError, onLogin }) {
  const classes = useStyles();
  const { register, handleSubmit, errors } = useForm();

  const onSubmit = data => {
    onLogin(data);
  };

  return (
    <Container component="main" maxWidth="xs">
      <div className={classes.paper}>
        <Avatar alt="Onward Pin" className={classes.avatar} src={onwardPin} />
        <Typography component="h1" variant="h5">
          Sign in
        </Typography>
        {loginError && (
          <Alert severity="error">
            Either your email or password is incorrect. If you do not remember
            your password, you can reset it below.
          </Alert>
        )}
        <form
          className={classes.form}
          noValidate
          onSubmit={handleSubmit(onSubmit)}
        >
          <TextField
            variant="outlined"
            margin="normal"
            required
            fullWidth
            id="email"
            label="Email Address"
            name="email"
            autoComplete="email"
            autoFocus
            error={errors.email}
            helperText={
              errors.email &&
              ((errors.email.type === 'required' && 'Email is required') ||
                (errors.email.type === 'validate' && 'Email is invalid'))
            }
            inputRef={register({
              required: true,
              validate: e => !!validEmail(e),
            })}
          />

          <TextField
            variant="outlined"
            margin="normal"
            required
            fullWidth
            name="password"
            label="Password"
            type="password"
            id="password"
            autoComplete="current-password"
            error={errors.password}
            helperText={
              errors.password &&
              errors.password.type === 'required' &&
              'Email is required'
            }
            inputRef={register({ required: true })}
          />

          <Button
            type="submit"
            fullWidth
            variant="contained"
            color="primary"
            className={classes.submit}
          >
            Sign In
          </Button>
          <Grid container>
            <Grid item xs>
              <Link component={RouterLink} to="/resetPassword" variant="body2">
                Forgot password?
              </Link>
            </Grid>
            <Grid item>
              <Typography data-testid="no-account" variant="body2">
                Don't have an account?{' '}
                <Link
                  variant="body2"
                  href="mailto:support@onwardrides.com?subject=Onward Rides Sign-up Request"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Contact Us
                </Link>
              </Typography>
            </Grid>
          </Grid>
        </form>
      </div>
      <Box mt={8}>
        <Copyright />
      </Box>
    </Container>
  );
}

export default function LoginPage() {
  const [loginError, setLoginError] = useState(false);

  let history = useHistory();
  let location = useLocation();
  let auth = useAuth();
  const tracking = useTracking();

  useEffect(() => {
    tracking.track('Login Viewed');
  }, [tracking]);

  const onSuccess = email => () => {
    tracking.identify(email);
    tracking.track('Login Success');
    history.replace(from);
  };

  const onFailure = () => {
    tracking.track('Login Failed');
    setLoginError(true);
  };
  let { from } = location.state || { from: { pathname: '/' } };

  let login = async data => {
    tracking.track('Login Attempt');

    const { email, password } = data;

    const resp = await fetch(`${apiUrl}/custodian_auth/sign_in`, {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ email, password }),
    });

    if (resp.status === 200) {
      const { data } = await resp.json();
      const expiry = resp.headers.get('expiry') || '';
      const client = resp.headers.get('client') || '';
      const uid = resp.headers.get('uid') || '';
      const accessToken = resp.headers.get('access-token') || '';

      auth.storeAuthHeaders({ expiry, client, id: data.id, uid, accessToken });
      onSuccess(email);
    } else {
      onFailure();
    }
  };

  const search = useLocation().search;

  useEffect(() => {
    const accessToken = getToken()?.accessToken;

    if (accessToken) {
      const authHeaders = auth.getAuthRequestHeaders();
      const currentAccessToken = authHeaders['access-token'];

      if (accessToken !== currentAccessToken) {
        deleteToken();
        window.ReactNativeWebView?.postMessage('WINDOW_CLOSED');
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (search) {
      const queryParams = new URLSearchParams(search);

      const accessToken = queryParams.get('access-token');
      const client = queryParams.get('client');
      const uid = queryParams.get('uid');
      const expiry = queryParams.get('expiry');

      const from = queryParams.get('from');
      const localIp = queryParams.get('localIp');
      const userId = queryParams.get('userId');

      if (from === 'RN') {
        auth.setFromRN(true);
      }
      if (localIp) {
        setLocalhostIp(localIp);
      }

      auth.storeAuthHeaders(
        {
          expiry,
          client,
          uid,
          accessToken,
          id: userId,
        },
        localIp,
      );
    }
  }, [search, auth]);

  return <LoginComponent loginError={loginError} onLogin={login} />;
}
