import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  List,
  ListItem,
  ListItemIcon,
  ListItemSecondaryAction,
  ListItemText,
  ListSubheader,
  makeStyles,
} from '@material-ui/core';
import FavoriteIcon from '@material-ui/icons/Favorite';
import FavoriteBorderIcon from '@material-ui/icons/FavoriteBorder';
import { firstLastInitial } from '@onwardcare/core/lib/utils/name-utils';
import PersonImage from 'components/PersonImage';
import ZeroStateText from 'components/ZeroStateText';
import _ from 'lodash';
import React from 'react';

const useStyles = makeStyles(theme => ({
  root: {
    maxWidth: 360,
    maxHeight: 500,
    overflow: 'scroll',
  },
  driverRow: {
    width: 300,
  },
  zeroText: {
    marginLeft: theme.spacing(3),
  },
}));

const FavoriteDriverForm = ({
  favoriteDrivers,
  recentDrivers,
  onClose,
  open,
  onDriverSelection,
}) => {
  const classes = useStyles();
  const renderDriver = (driver, isFavorite) => {
    return (
      <ListItem key={`driver_${driver.id}`} className={classes.driverRow}>
        <ListItemIcon>
          <PersonImage person={driver} />
        </ListItemIcon>
        <ListItemText id="heart-label" primary={firstLastInitial(driver)} />
        <ListItemSecondaryAction>
          <IconButton
            aria-label={isFavorite ? 'unfavorite' : 'favorite'}
            onClick={() => onDriverSelection(driver.id, !isFavorite)}
          >
            {isFavorite ? (
              <FavoriteIcon color="secondary" />
            ) : (
              <FavoriteBorderIcon color="secondary" />
            )}
          </IconButton>
        </ListItemSecondaryAction>
      </ListItem>
    );
  };

  const faveDrivers = _.sortBy(favoriteDrivers, 'firstName');

  const nonFaveDrivers = _.sortBy(recentDrivers, 'firstName').filter(
    d => !_.find(favoriteDrivers, fd => fd.id === d.id),
  );

  const renderFavoriteDrivers = () => {
    if (faveDrivers.length > 0) {
      return faveDrivers.map(fd => renderDriver(fd, true));
    }

    return (
      <Box className={classes.zeroText}>
        <ZeroStateText>No favorite drivers selected.</ZeroStateText>
      </Box>
    );
  };

  const renderNonFavoriteDrivers = () => {
    if (nonFaveDrivers.length > 0) {
      return nonFaveDrivers.map(fd => renderDriver(fd, false));
    }

    const zeroText =
      recentDrivers.length === 0
        ? "Drivers will show up here after the rider's first ride."
        : 'All previous drivers have been favorited.';

    return (
      <Box className={classes.zeroText}>
        <ZeroStateText>{zeroText}</ZeroStateText>
      </Box>
    );
  };

  return (
    <Dialog
      // disableBackdropClick
      // disableEscapeKeyDown
      maxWidth="xs"
      // onEntering={handleEntering}
      aria-labelledby="confirmation-dialog-title"
      open={open}
      // {...other}
    >
      <DialogTitle id="confirmation-dialog-title">
        Update Favorite Drivers
      </DialogTitle>
      <DialogContent dividers className={classes.root}>
        <List subheader={<ListSubheader>Favorite Drivers</ListSubheader>}>
          {renderFavoriteDrivers()}
        </List>

        <List subheader={<ListSubheader>Recent Drivers</ListSubheader>}>
          {renderNonFavoriteDrivers()}
        </List>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="primary">
          Ok
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default FavoriteDriverForm;
