import { useEffect, useState } from 'react';
import { oauth2 as SMART } from 'fhirclient';
import PageLoadingSpinner from '../components/ehr/PageLoadingSpinner';
import { useLocation } from 'react-router-dom';
import EhrErrorMessage from 'components/ehr/EhrErrorMessage';
import ErrorHandler from '../lib/error-handler';
import ENV from '../constants/Env';
import { EhrSession, saveEhrSession } from '../contexts/ehr-context';

const { apiUrl } = ENV;

type PageState = 'loading' | 'missingParams' | 'error' | 'success';

/**
 * This is the initial page used to launch the app in the context of an EHR (Epic, Cerner, etc).
 */
export default function Launcher() {
  const location = useLocation();
  const urlParams = new URLSearchParams(location.search);
  const appId = urlParams.get('appId');
  const isTest = urlParams.get('test') !== null;
  const iss = urlParams.get('iss');
  const [ehrClientId, setEhrClientId] = useState<string | null>(null);
  const [scope, setScope] = useState<string | null>(null);
  const [state, setState] = useState<PageState>('loading');
  const [missingFields, setMissingFields] = useState<string[]>([]);
  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    const fetchAppRecord = async () => {
      if (appId === null) {
        setState('error');
        const errorMessage = 'Missing the FHIR app ID';
        setError(errorMessage);
        ErrorHandler.captureMessage(`[Epic EHR] - Launcher - ${errorMessage}`);
        return;
      }

      try {
        const response = await fetch(`${apiUrl}/api/ehr/fhir_apps/${appId}`, {
          headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
          },
        });

        if (response.ok) {
          const data = await response.json();

          if (data.retired) {
            setState('error');
            const errorMessage = `The FHIR app with ID "${appId}" has been retired. Please contact your administrator.`;
            setError(errorMessage);
            ErrorHandler.captureMessage(
              `[Epic EHR] - Launcher - ${errorMessage}`,
            );
            return;
          }

          const clientId = isTest ? data.non_prod_client_id : data.client_id;
          const session: EhrSession = {
            fhirAppId: appId ? Number(appId) : null,
            fhirAppClientId: clientId,
            testSession: isTest,
            ehrAccessToken: null,
            ehrSessionId: null,
          };

          // We need to save this in session storage so that after the EHR
          // redirects us back to the app, we can pull this data out and put it
          // in the ehr-context.
          saveEhrSession(session);
          setEhrClientId(clientId);
          setScope(data.scope);

          ErrorHandler.captureMessage(
            `[Epic EHR] - Launcher - Using FHIR app ID "${appId}" in ${
              isTest ? 'test' : 'production'
            } mode`,
          );
        } else {
          setState('error');
          let errorMessage = 'Unknown error';

          if (response.status === 404) {
            errorMessage = `Could not find FHIR app for ID '${appId}'`;
          } else {
            errorMessage = `Failed to fetch the FHIR app record: ${response.status} - ${response.statusText}`;
          }

          setError(errorMessage);
          ErrorHandler.captureMessage(
            `[Epic EHR] - Launcher - ${errorMessage}`,
          );
        }
      } catch (error) {
        setState('error');
        ErrorHandler.captureException(error);
        const errorMessage =
          error instanceof Error ? error.message : 'Unknown error';
        setError(errorMessage);
        ErrorHandler.captureMessage(
          '[Epic EHR] - Launcher - Error trying to fetch the FHIR app record',
        );
      }
    };

    fetchAppRecord();
  }, [appId, isTest]);

  useEffect(() => {
    if (!ehrClientId || !scope) {
      // We haven't finished the API call to get the FHIR app record yet.
      return;
    }

    if (!iss) {
      setState('missingParams');
      setMissingFields(['iss']);
      ErrorHandler.captureMessage('[Epic EHR] - Launcher - Missing ISS');
      return;
    }

    const authorizeFhirClient = async () => {
      try {
        await SMART.authorize({
          clientId: ehrClientId,
          scope,
          redirectUri: '/launch/session',
          // NOTE: `iss` and `launch` are pulled from the URL, so don't pass them in.

          // This is needed to make this work in an iframe.
          completeInTarget: true,
        });

        setState('success');
        ErrorHandler.captureMessage(
          '[Epic EHR] - Launcher - Successfully authorized with SMART on FHIR',
        );
      } catch (error) {
        setState('error');
        ErrorHandler.captureException(error);
        const errorMessage =
          error instanceof Error ? error.message : 'Unknown error';
        setError(errorMessage);
        ErrorHandler.captureMessage(
          `[Epic EHR] - Launcher - Error trying to authorize with SMART on FHIR: ${errorMessage}`,
        );
      }
    };

    authorizeFhirClient();
  }, [ehrClientId, iss, scope]);

  return (
    <>
      {(state === 'loading' || state === 'success') && <PageLoadingSpinner />}

      {state === 'missingParams' && (
        <EhrErrorMessage>
          <EhrErrorMessage.Title>
            Could not authorize against the EHR context
          </EhrErrorMessage.Title>

          <EhrErrorMessage.Body>
            <p>The following parameters are missing:</p>

            <ul>
              {missingFields.map(field => (
                <li>{field}</li>
              ))}
            </ul>
          </EhrErrorMessage.Body>
        </EhrErrorMessage>
      )}

      {state === 'error' && (
        <EhrErrorMessage>
          <EhrErrorMessage.Title>
            Could not authorize against the EHR context
          </EhrErrorMessage.Title>

          <EhrErrorMessage.Body>
            <p>{error || 'Unknown error'}</p>
          </EhrErrorMessage.Body>
        </EhrErrorMessage>
      )}
    </>
  );
}
