import React, { useCallback, useEffect, useState } from 'react';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  Typography,
} from '@material-ui/core';
import { RideTimeHelperProps } from './props';
import { useStyles } from './styles';
import AccessTimeIcon from '@material-ui/icons/AccessTime';
import { MaterialUiPickersDate } from '@material-ui/pickers/typings/date';
import moment from 'moment';
import { HOUR_FORMAT_24 } from 'lib/TimeUtils';
import { RideTimeError } from './RideTimeError/RideTimeError';
import { RideTimeHelperForm } from './RideTimeHelperForm/RideTimeHelperForm';

const _RideTimeHelper: React.FC<RideTimeHelperProps> = ({
  defaultDate,
  defaultTime,
  disabled,
  ride,
  bufferMinutes,
  handleSubmit,
  isFlexibleRide,
}) => {
  const classes = useStyles();
  const [dialogOpen, setDialogOpen] = useState<boolean>(false);
  const [arrivalTime, setArrivalTime] = useState<string>('');
  const [arrivalDate, setArrivalDate] = useState<MaterialUiPickersDate | null>(
    null,
  );
  const [pickupTime, setPickupTime] = useState<any>('');
  const [additionalTime, setAdditionalTime] = useState<number | undefined>(undefined);

  const [localDisabled, setLocalDisabled] = useState<boolean>(false);
  const [error, setError] = useState<boolean>(false);

  const additionalTimeOptions = [0, 10, 15, 30, 45, 60];

  const setDataToDefault = () => {
    setArrivalDate(null);
    setArrivalTime('');
    setPickupTime('');
    setAdditionalTime(undefined);
  };

  const handleTimeHelperClick = () => {
    setDialogOpen(true);
  };

  const handleCloseDialog = () => {
    setDataToDefault();
    setDialogOpen(false);
  };

  const formatTimeString = useCallback(() => {
    return moment(pickupTime.clean).format(HOUR_FORMAT_24);
  }, [pickupTime]);

  const onSubmit = () => {
    const timeString = formatTimeString();
    handleSubmit(additionalTime || 0, timeString, moment(pickupTime.clean));
    setDialogOpen(false);
  };

  useEffect(() => {
    setLocalDisabled(moment(pickupTime?.clean).isBefore(moment()));
  }, [pickupTime, setLocalDisabled]);

  /**
   * The actualBufferMinutes variable is used to set the default buffer 
   * minutes.
   * 
   * 1) It starts with a default value of 15.
   * 2) If there is additionalTime - indicating the user updated the buffer minutes in the form - use it.
   * 3) If there is no additionalTime, it means the user didn't update the buffer minutes in the form yet.
   * 4) Check if bufferMinutes is set; this means the user is in Edit Mode. If so, use that value.
   */

  // 1)
  let actualBufferMinutes = 15;
  if (additionalTime !== undefined) {
    // 2)
    actualBufferMinutes = additionalTime;
  } else {
    // 3)
    if (bufferMinutes !== undefined) {
      // 4)
      actualBufferMinutes = bufferMinutes;
    }
  }
  const buttonDisabled = disabled || isFlexibleRide;
  
  return (
    <div>
      <IconButton disabled={buttonDisabled} onClick={handleTimeHelperClick}>
        <Button
          disabled={buttonDisabled}
          onClick={handleTimeHelperClick}
          variant="contained"
          color="primary"
        >
          <div className={classes.button}>
            <AccessTimeIcon className={classes.icon} />
            <Typography className={classes.label}>
              Arrive by Calculator
            </Typography>
          </div>
        </Button>
      </IconButton>
      <Dialog open={dialogOpen} onClose={handleCloseDialog}>
        <DialogTitle>Arrive By Time Calculator</DialogTitle>
        <DialogContent>
          <Typography>
            Use the form below to help calculate a pickup time to ensure on time
            arrival.
          </Typography>
          <RideTimeHelperForm
            pickupTime={pickupTime}
            setPickupTime={setPickupTime}
            defaultDate={defaultDate}
            defaultTime={defaultTime}
            bufferMinutes={actualBufferMinutes}
            setBufferMinutes={setAdditionalTime}
            arrivalDate={arrivalDate}
            setArrivalDate={setArrivalDate}
            arrivalTime={arrivalTime}
            setArrivalTime={setArrivalTime}
            bufferMinutesOptions={additionalTimeOptions}
            ride={ride}
            setError={setError}
          />
        </DialogContent>
        {localDisabled && <RideTimeError error='The calculated pickup time is in the past. Please select a future time.'/>}
        <DialogActions>
          <Button onClick={handleCloseDialog} color="primary">
            Close
          </Button>
          {!error &&
            <Button
              onClick={onSubmit}
              color="primary"
              variant="contained"
              disabled={localDisabled}
            >
              Update Pickup Time
            </Button>
          }
        </DialogActions>
      </Dialog>
    </div>
  );
};

export const RideTimeHelper = React.memo(_RideTimeHelper);
