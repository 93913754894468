import onwardColors from '../../lib/onwardColors';
import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles(theme => ({
  withTooltip: {
    display: 'flex',
    alignItems: 'center',
  },
  left: {
    padding: theme.spacing(2),
  },
  leftGrid: {
    [theme.breakpoints.up('sm')]: {
      overflow: 'scroll',
    },
  },
  formControl: {
    margin: theme.spacing(1),
    width: '95%',
  },
  formTimeControl: {
    margin: theme.spacing(1),
    width: '95%',
    alignItems: 'center',
  },
  fareSection: {
    margin: theme.spacing(1),
    padding: theme.spacing(1),
    width: '95%',
    borderLeftColor: onwardColors.onwardBlue,
    borderLeftWidth: 3,
    borderLeftStyle: 'solid',
    paddingLeft: '15px',
  },
  startDay: {
    marginRight: theme.spacing(2),
  },
  form: {
    marginTop: theme.spacing(1),
    display: 'flex',
    flexDirection: 'column',
  },
  formActions: {
    padding: theme.spacing(3, 1, 3, 1),
  },
  formActionsValidation: {
    margin: theme.spacing(0, 0, 1, 0),
  },
  submit: {
    width: '100%',
  },
  container: {
    height: '100vh',
  },
  instructionLink: {
    marginTop: -1 * theme.spacing(1),
  },
  addInstructionText: {
    fontSize: 'small',
  },
  addPaymentText: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(3),
  },
  cardIcon: {
    marginRight: theme.spacing(1),
  },
  estimate: {
    marginTop: theme.spacing(1.5),
  },
  block: {
    display: 'block',
  },
  cardStyle: {
    width: '100%',
  },
  root: {
    color: theme.palette.error.main,
  },
  heightContainer: {
    marginTop: theme.spacing(2),
  },
  locationPhoto: {
    padding: theme.spacing(1),
  },
}));
