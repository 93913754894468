import React from 'react';

import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FormControl,
  MenuItem,
  TextField,
} from '@material-ui/core';
import { useForm, Controller } from 'react-hook-form';
import { Alert } from '@material-ui/lab';
import {
  validEmail,
  validPhone,
} from '@onwardcare/core/lib/validation/form-validation';
import { Grid } from '@material-ui/core';
import {
  AddCustodianMutationVariables,
  Custodian,
} from '../../../generated/graphql';
import { clinicalCredentials } from './ClinicalCredentialsList';

type EditCoordinatorDialogProps = {
  open: boolean;
  handleClose: () => void;
  handleUpdate: (
    custodianId: Custodian['id'],
    data: AddCustodianMutationVariables['custodian'],
  ) => void;
  errorMessage: any;
  channel?: string | null;
  custodian: Custodian;
};

export const EditCoordinatorDialog: React.FC<EditCoordinatorDialogProps> = ({
  custodian,
  open,
  handleClose,
  handleUpdate,
  errorMessage,
  channel,
}) => {
  const { handleSubmit, errors, register, control } = useForm();

  const submit = (data: AddCustodianMutationVariables['custodian']) => {
    handleUpdate(custodian.id, data);
  };

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby="form-dialog-title"
    >
      <form noValidate onSubmit={handleSubmit(submit)}>
        <DialogTitle id="form-dialog-title">
          Update Coordinator Details
        </DialogTitle>

        <DialogContent>
          {errorMessage && <Alert severity="error">{errorMessage}</Alert>}

          <DialogContentText>
            Update this coordinator's details.
          </DialogContentText>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={6}>
              <TextField
                required
                fullWidth
                id="firstName"
                name="firstName"
                label="First Name"
                defaultValue={custodian?.firstName}
                error={!!errors.firstName}
                helperText={errors.firstName && 'First Name is required'}
                inputRef={register({
                  required: true,
                })}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                required
                id="lastName"
                fullWidth
                name="lastName"
                label="Last Name"
                defaultValue={custodian?.lastName}
                error={!!errors.lastName}
                helperText={errors.lastName && 'Last Name is required'}
                inputRef={register({
                  required: true,
                })}
              />
            </Grid>

            <Grid item xs={12} sm={6}>
              <TextField
                required
                id="phone"
                fullWidth
                name="phone"
                label="Phone"
                defaultValue={custodian?.phone}
                error={!!errors.phone}
                helperText={errors.phone && 'A valid phone number is required'}
                inputRef={register({
                  required: true,
                  validate: validPhone,
                })}
              />
            </Grid>

            <Grid item xs={12} sm={6}>
              <TextField
                required
                id="email"
                fullWidth
                name="email"
                label="Email"
                defaultValue={custodian?.email}
                error={!!errors.email}
                helperText={errors.email && 'A valid email is required'}
                inputRef={register({
                  required: true,
                  validate: validEmail,
                })}
              />
            </Grid>

            {channel === 'b2b' && (
              <Grid item xs={12} sm={6}>
                <FormControl fullWidth>
                  <Controller
                    name="clinicalCredential"
                    control={control}
                    defaultValue={custodian.clinicalCredential}
                    render={({ onChange, onBlur, value }) => (
                      <TextField
                        select
                        label="Clinical Credential"
                        onChange={onChange}
                        onBlur={onBlur}
                        value={value}
                        error={!!errors.clinicalCredential}
                        helperText={errors.clinicalCredential?.message}
                      >
                        <MenuItem value={''}>Not specified</MenuItem>
                        {clinicalCredentials.map(clinicalCredential => (
                          <MenuItem
                            key={clinicalCredential}
                            value={clinicalCredential}
                          >
                            {clinicalCredential}
                          </MenuItem>
                        ))}
                      </TextField>
                    )}
                  />
                </FormControl>
              </Grid>
            )}
          </Grid>
        </DialogContent>

        <DialogActions>
          <Button onClick={handleClose} color="primary">
            Cancel
          </Button>
          <Button type="submit" color="primary">
            Update
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  );
};
