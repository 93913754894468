import {
  Box,
  Button,
  Container,
  Grid,
  makeStyles,
  Paper,
  TextField,
  Typography,
  useMediaQuery,
  useTheme,
} from '@material-ui/core';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import { Alert } from '@material-ui/lab';
import {
  validEmail,
  validPhone,
} from '@onwardcare/core/lib/validation/form-validation';
import { NotifPrefSelector } from 'components/NotifPrefSelector';
import SectionHeader from 'components/SectionHeader';
import React from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useHistory } from 'react-router-dom';
import { useAuth } from '../../contexts/auth-context';
import { without, concat } from 'lodash';
import { useEhrContext } from '../../contexts/ehr-context';

const useStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(2),
  },
  formControl: {
    margin: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 1, 3, 1),
    width: '95%',
  },
  section: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
  },
}));

function CustodianProfileForm({ custodian, updateError, onUpdate }) {
  const classes = useStyles();
  const theme = useTheme();
  const auth = useAuth();

  const isFromRN = auth?.getFromRN(); // user login from react-native app using webview
  const smallScreen = useMediaQuery(theme.breakpoints.down('sm'));
  const history = useHistory();
  const { isEhr } = useEhrContext();

  const { firstName, lastName, email, phone, notifPrefs } = custodian;
  const defaultValues = { firstName, lastName, email, phone, notifPrefs };

  const {
    handleSubmit,
    errors,
    getValues,
    setValue,
    register,
    control,
  } = useForm({
    defaultValues,
  });

  const submit = data => {
    onUpdate(data);
  };

  React.useEffect(() => {
    // for react native webview
    const listner = event => {
      if (!event.origin.includes('stripe')) {
        const message = event.data;
        const notifPrefsValues = getValues('notifPrefs');
        const notifPrefsValuesCopy = notifPrefsValues.map(e => e);

        if (notifPrefsValuesCopy.includes(message.value)) {
          setValue('notifPrefs', without(notifPrefsValuesCopy, message.value));
        } else {
          setValue('notifPrefs', concat(notifPrefsValuesCopy, message.value));
        }
      }
    };

    window.addEventListener('message', listner);

    return () => window.removeEventListener('message', listner);
  }, [getValues, setValue]);

  const openPushNotificator = value => {
    // for react native webview
    window.ReactNativeWebView?.postMessage(`OPEN_NOTIFICATOR:${value}`);
  };

  return (
    <Container maxWidth="md" disableGutters={smallScreen}>
      <Button
        color="primary"
        startIcon={<ArrowBackIcon />}
        onClick={() => history.goBack()}
      >
        Back
      </Button>
      <Paper className={classes.root}>
        <Typography component="h1" variant="h4">
          My Profile
        </Typography>
        {updateError && (
          <Alert severity="error">
            There was an unexpected error updating your profile. Please wait a
            minute and then try again.
          </Alert>
        )}

        <form
          className={classes.form}
          noValidate
          onSubmit={handleSubmit(submit)}
        >
          <SectionHeader title="Contact Information" />
          <Grid container spacing={2} className={classes.section}>
            <Grid item xs={12} sm={6}>
              <TextField
                fullWidth
                id="firstName"
                name="firstName"
                label="First Name"
                error={!!errors.firstName}
                helperText={errors.firstName && 'First Name is required'}
                inputRef={register({
                  required: true,
                })}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                id="lastName"
                fullWidth
                name="lastName"
                label="Last Name"
                error={!!errors.lastName}
                helperText={errors.lastName && 'Last Name is required'}
                inputRef={register({
                  required: true,
                })}
              />
            </Grid>

            <Grid item xs={12} sm={6}>
              <TextField
                id="phone"
                fullWidth
                name="phone"
                label="Phone"
                error={!!errors.phone}
                helperText={errors.phone && 'A valid phone number is required'}
                inputRef={register({
                  required: true,
                  validate: p => validPhone(p),
                })}
              />
            </Grid>

            <Grid item xs={12} sm={6}>
              <TextField
                id="email"
                fullWidth
                name="email"
                label="Email"
                autoComplete="email"
                disabled={isEhr}
                error={!!errors.email}
                helperText={errors.email && 'A valid email is required'}
                inputRef={register({
                  required: true,
                  validate: e => validEmail(e),
                })}
              />
            </Grid>
          </Grid>

          {isEhr === false && (
            <>
              <SectionHeader title="Password" />
              <Box className={classes.section}>
                <Typography variant="body1">
                  Fill this out to update your password. Leave blank to keep
                  your password the same.
                </Typography>

                <Grid container spacing={2}>
                  <Grid item xs={12} sm={6}>
                    <TextField
                      id="password"
                      fullWidth
                      name="password"
                      label="Password"
                      error={!!errors.password}
                      autoComplete="current-password"
                      type="password"
                      helperText={
                        errors.password &&
                        'The entered password does not match the confirmation'
                      }
                      inputRef={register({
                        validate: p => {
                          let passwordConfirmation = getValues(
                            'passwordConfirmation',
                          );
                          if (p || passwordConfirmation) {
                            return p === passwordConfirmation;
                          }
                        },
                      })}
                    />
                  </Grid>

                  <Grid item xs={12} sm={6}>
                    <TextField
                      id="passwordConfirmation"
                      fullWidth
                      name="passwordConfirmation"
                      label="Password Confirmation"
                      type="password"
                      error={!!errors.password}
                      autoComplete="current-password"
                      inputRef={register()}
                    />
                  </Grid>
                </Grid>
              </Box>
            </>
          )}

          <SectionHeader title="Notification Preferences" />
          <Box className={classes.section}>
            <Typography variant="body1">
              Your preferred method(s) of communication with the Onward team.
            </Typography>
            <Controller
              control={control}
              name="notifPrefs"
              render={props => (
                <NotifPrefSelector
                  onChange={props.onChange}
                  value={props.value}
                  pushAvailable={isFromRN}
                  onPushPress={
                    !custodian.pushToken ? openPushNotificator : undefined
                  }
                />
              )}
            />
          </Box>

          <Button
            key="updateButton"
            type="submit"
            variant="contained"
            color="primary"
            className={classes.submit}
          >
            Update Profile
          </Button>
        </form>
      </Paper>
    </Container>
  );
}
export default CustodianProfileForm;
