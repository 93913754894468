import {
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  Tooltip,
  Typography,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import EditIcon from '@material-ui/icons/Edit';
import EmojiObjectsIcon from '@material-ui/icons/EmojiObjects';
import HearingIcon from '@material-ui/icons/Hearing';
import VisibilityIcon from '@material-ui/icons/Visibility';
import { impairmentSummary } from '@onwardcare/core/lib/utils/impairment-utils';
import { mobilitySummary } from '@onwardcare/core/lib/utils/mobility-utils';
import { personName } from '@onwardcare/core/lib/utils/name-utils';
import { capitalizeFirstLetter } from '../../helpers/helpers';
import { formatPhoneNumber } from '@onwardcare/core/lib/utils/phone-utils';
import PersonImage from 'components/PersonImage';
import { Link } from 'react-router-dom';
// @ts-ignore
import ShowMoreText from 'react-show-more-text';
import { ElderlyIcon } from '../icons/icons';
import { useVetridesContext } from '../../contexts/vetrides-context';
import { Ride } from '../../generated/graphql';

const useStyles = makeStyles(theme => ({
  personSection: {
    marginTop: theme.spacing(2),
    height: '100%',
  },
  avatar: {
    marginRight: theme.spacing(1),
  },
  bio: {
    marginTop: theme.spacing(1),
  },
  smallBox: {
    height: '100%',
  },
  riderIcon: {
    marginRight: theme.spacing(0.75),
  },
  partner: {
    marginLeft: theme.spacing(1),
  },
}));

type Props = {
  ride: Ride;
};

const RiderCard: React.FC<Props> = ({ ride }) => {
  const classes = useStyles();
  const { isVetride } = useVetridesContext();

  const renderRiderDetails = () => {
    const { rider } = ride;

    if (!rider) {
      return null;
    }

    return (
      <>
        <Box display="flex">
          <Box className={classes.avatar}>
            <PersonImage person={rider} size="large" />
          </Box>
          <Box flexGrow={1}>
            <Typography variant="h6">{personName(rider)}</Typography>
            {rider.phone && (
              <Typography variant="subtitle2">
                {formatPhoneNumber(rider.phone)}
              </Typography>
            )}
          </Box>
          <Box>
            {rider.mobilityAssistance !== 'none' && (
              <Tooltip
                title={mobilitySummary(rider.mobilityAssistance)}
                aria-label="mobility assistance"
              >
                <ElderlyIcon />
              </Tooltip>
            )}
            {rider.visionImpairment !== 'none' && (
              <Tooltip
                title={
                  impairmentSummary(rider.visionImpairment) +
                  ' vision impairment'
                }
                aria-label="vision impairment"
              >
                <VisibilityIcon className={classes.riderIcon} />
              </Tooltip>
            )}
            {rider.hearingImpairment !== 'none' && (
              <Tooltip
                title={
                  impairmentSummary(rider.hearingImpairment) +
                  ' hearing impairment'
                }
                aria-label="hearing impairment"
              >
                <HearingIcon className={classes.riderIcon} />
              </Tooltip>
            )}
            {rider.cognitiveImpairment !== 'none' && (
              <Tooltip
                title={
                  impairmentSummary(rider.cognitiveImpairment) +
                  ' cognitive impairment'
                }
                aria-label="cognitive impairment"
              >
                <EmojiObjectsIcon className={classes.riderIcon} />
              </Tooltip>
            )}
          </Box>
        </Box>
        <Box className={classes.bio}>
          {rider.notes && (
            <ShowMoreText lines={5} keepNewLines>
              {rider.notes}
            </ShowMoreText>
          )}
        </Box>
        <RenderPartnerCard />
      </>
    );
  };

  const renderRiderActions = () => {
    return isVetride ? null : (
      <Button
        color="primary"
        variant="outlined"
        component={Link}
        to={`/riders/${ride?.rider?.id}`}
        startIcon={<EditIcon />}
      >
        Edit
      </Button>
    );
  };

  const RenderPartnerCard = () => {
    const { rider } = ride;
    const carePartner = rider?.carePartner;

    if (!carePartner) {
      return null;
    }

    const relationshipType = carePartner.relationshipType.toLowerCase();
    const relationshipTypeName =
      relationshipType !== 'other'
        ? capitalizeFirstLetter(relationshipType.split('_').join(' '))
        : 'Care Partner';

    return (
      <>
        <Box display="flex">
          <Box className={classes.avatar}>
            <PersonImage person={carePartner} size="large" />
          </Box>
          <Box flexGrow={1}>
            <Typography variant="h6">
              {personName(carePartner)}
              {', '}
              {relationshipTypeName}
            </Typography>
            {carePartner.twilioPhone && (
              <Typography variant="subtitle2">
                {formatPhoneNumber(carePartner.twilioPhone)}
              </Typography>
            )}
          </Box>
        </Box>
      </>
    );
  };

  return (
    <Card className={classes.personSection}>
      <CardHeader title="Rider" action={renderRiderActions()} />
      <CardContent>{renderRiderDetails()}</CardContent>
    </Card>
  );
};

export default RiderCard;
