import {
  Box,
  Button,
  Grid,
  makeStyles,
  TextField,
  Typography,
  Select,
  MenuItem,
  InputLabel,
  FormHelperText,
  Dialog,
  DialogTitle,
  DialogActions,
} from '@material-ui/core';
import AccessibleIcon from '@material-ui/icons/Accessible';
import EmojiObjectsIcon from '@material-ui/icons/EmojiObjects';
import HearingIcon from '@material-ui/icons/Hearing';
import VisibilityIcon from '@material-ui/icons/Visibility';
import { Alert } from '@material-ui/lab';
import {
  validEmail,
  validPhone,
} from '@onwardcare/core/lib/validation/form-validation';
import AvatarUpload from 'components/AvatarUpload';
import { NotifPrefSelector } from 'components/NotifPrefSelector';
import { ImpairmentInput } from 'components/riders/ImpairmentInput';
import SectionHeader from 'components/SectionHeader';
import React, { useState, useEffect, useCallback } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { KeyboardDatePicker } from '@material-ui/pickers';
import { useLocation } from 'react-router-dom';
import GooglePlacesAutocomplete from 'components/GooglePlacesAutocomplete';
import {
  CarePartnerRelationship,
  Maybe,
  PersonGender,
  Rider,
  useRemoveCarePartnerMutation,
} from '../../../generated/graphql';
import { capitalizeFirstLetter, isValidDate } from '../../../helpers/helpers';
import onwardColors from 'lib/onwardColors.js';
import {
  getCmFromInches,
  getInchesFromCM,
  getKgFromLBS,
  getLBSFromKg,
} from '../../rides/rideFormComponents/helpers';
import { maxWeight } from '../../rides/rideFormComponents/constants';
import { MetricSwitch } from '../../common/MetricSwitch';
import { readIsMetric } from '../../../storages/is-metric-storage';
import { useLanguagesQuery } from 'generated/graphql';
import moment from 'moment';

const useStyles = makeStyles(theme => ({
  submit: {
    margin: theme.spacing(3, 1, 3, 1),
    width: '95%',
  },
  section: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
  },
  impairmentGridItem: {
    marginBottom: theme.spacing(2),
  },
  avatarUpload: {
    marginTop: theme.spacing(2),
  },
}));

type Props = {
  rider: Rider | undefined | null;
  updateError: any;
  onUpdate: (args: any) => void;
  newProfile?: boolean;
  getRiderProfile?: () => void;
};

export const ProfileTab: React.FC<Props> = ({
  rider,
  updateError,
  onUpdate,
  newProfile = false,
  getRiderProfile,
}) => {
  const [
    openConfirmRemoveCarePartner,
    setOpenConfirmRemoveCarePartner,
  ] = useState(false);
  const [showCarePartner, setShowCarePartner] = useState(!!rider?.carePartner);
  const isMetric = readIsMetric();

  const [useMetric, setUseMetric] = useState(isMetric);

  const classes = useStyles();

  const { data: languagesData } = useLanguagesQuery();
  const languages = languagesData?.languages;

  const {
    handleSubmit,
    errors,
    getValues,
    register,
    control,
    watch,
    setValue,
    trigger,
  } = useForm();

  const [removeCarePartner, { loading }] = useRemoveCarePartnerMutation();

  const submit = (
    data: Partial<Rider> & {
      heightCm: Maybe<number>;
      heightFeet: Maybe<number>;
      heightInch: Maybe<number>;
      weight: Maybe<number>;
    },
  ) => {
    const {
      carePartner,
      heightCm,
      heightFeet,
      heightInch,
      weight,
      ...rest
    } = data;

    let heightInches = (heightFeet || 0) * 12 + (heightInch || 0);
    let weightLbs = weight || 0;

    if (useMetric) {
      heightInches = getInchesFromCM(heightCm || 0);
      weightLbs = getLBSFromKg(weight || 0);
    }

    if (!carePartner?.firstName || !carePartner?.lastName) {
      onUpdate({
        ...rest,
        heightInches,
        weightLbs,
      });
    } else {
      onUpdate({ ...rest, carePartner, heightInches, weightLbs });
    }
  };

  const carePartner = watch('carePartner');
  const weight = watch('weight');
  const heightInch = watch('heightInch');
  const heightFeet = watch('heightFeet');
  const heightCm = watch('heightCm');

  const location = useLocation();
  const urlParams = new URLSearchParams(location.search);
  const firstName = urlParams.get('firstName');
  const lastName = urlParams.get('lastName');
  const dob = urlParams.get('dob');

  const genders = Object.values(PersonGender).map(gender => ({
    id: gender,
    displayName: gender.charAt(0).toUpperCase() + gender.slice(1).toLowerCase(),
  }));

  const emailHelperText = () => {
    if (errors.email) {
      const notifPrefs = getValues('notifPrefs') || [];
      if (notifPrefs.includes('email')) {
        return 'A valid email is required to receive email notifications';
      } else {
        return 'Email is invalid';
      }
    }
    return null;
  };

  const validateEmail = (e: any) => {
    let notifPrefs = getValues('notifPrefs') || [];
    if (e || notifPrefs.includes('email')) {
      return validEmail(e);
    }
  };

  const phoneHelperText = () => {
    if (errors.phone) {
      const notifPrefs = getValues('notifPrefs') || [];
      if (notifPrefs.includes('sms') || notifPrefs.includes('voice')) {
        return 'A valid phone number is required to receive text message or voice notifications';
      } else {
        return 'Phone is invalid';
      }
    }
    return null;
  };

  const validatePhone = (p: any) => {
    let notifPrefs = getValues('notifPrefs') || [];
    if (p || notifPrefs.includes('sms') || notifPrefs.includes('voice')) {
      return validPhone(p);
    }
  };

  const validatePartnerEmail = (e: any) => {
    let notifPrefs = carePartner?.notifPrefs || [];
    if (e || notifPrefs.includes('email')) {
      return validEmail(e);
    }
  };

  useEffect(() => {
    if (rider) {
      setValue('carePartner.firstName', rider.carePartner?.firstName);
      setValue('carePartner.lastName', rider.carePartner?.lastName);
      setValue('carePartner.phone', rider.carePartner?.phone);
      setValue('carePartner.email', rider.carePartner?.email);
      setValue(
        'carePartner.relationshipType',
        rider.carePartner?.relationshipType,
      );
      setValue(
        'carePartner.relationshipNotes',
        rider.carePartner?.relationshipNotes,
      );
      setValue('carePartner.notifPrefs', rider.carePartner?.notifPrefs || []);
    }
  }, [rider]);

  useEffect(() => {
    setValue(
      'weight',
      useMetric ? getKgFromLBS(weight || 0) : getLBSFromKg(weight || 0),
    );

    if (useMetric) {
      const inches = (heightInch || 0) + (heightFeet || 0) * 12;
      setValue('heightCm', getCmFromInches(inches));
    } else {
      const inches = getInchesFromCM(heightCm);
      const heightInch = (inches || 0) % 12;
      const heightFeet = Math.trunc((inches || 0) / 12);
      setValue('heightInch', heightInch || 0);
      setValue('heightFeet', heightFeet || 0);
    }
  }, [useMetric]);

  useEffect(() => {
    if (rider) {
      const heightInchesRider = rider.heightInches;
      const weightLbsRider = rider.weightLbs;

      if (useMetric) {
        setValue('weight', getKgFromLBS(weightLbsRider || 0));
        setValue('heightCm', getCmFromInches(heightInchesRider || 0));
      } else {
        setValue('weight', weightLbsRider || 0);
        const heightInch = (heightInchesRider || 0) % 12;
        const heightFeet = Math.trunc((heightInchesRider || 0) / 12);
        setValue('heightInch', heightInch);
        setValue('heightFeet', heightFeet);
      }
    }
  }, [rider]);

  const disabledPartnerFields =
    !carePartner?.firstName || !carePartner?.lastName;

  const requiredPartnerFields =
    !disabledPartnerFields &&
    (!!carePartner?.firstName || !!carePartner?.lastName);

  const tryRemove = () => {
    setOpenConfirmRemoveCarePartner(true);
  };

  useEffect(() => {
    if (!disabledPartnerFields && !rider?.carePartner) {
      setValue('carePartner.notifPrefs', ['sms']);
    }
  }, [rider?.carePartner, disabledPartnerFields]);

  const changePhone = useCallback(
    (event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
      const phoneValue = event.target.value;

      setValue('phone', phoneValue);

      if (phoneValue) {
        let notifPrefs = getValues('notifPrefs');

        if (
          notifPrefs &&
          Array.isArray(notifPrefs) &&
          !notifPrefs.includes('sms')
        ) {
          notifPrefs = [...notifPrefs, 'sms'];
        }

        setValue('notifPrefs', notifPrefs);
      }

      if (phoneValue === '') {
        let notifPrefs = getValues('notifPrefs');
        if (
          notifPrefs &&
          Array.isArray(notifPrefs) &&
          notifPrefs.includes('sms')
        ) {
          notifPrefs = notifPrefs.filter(p => p !== 'sms');
          setValue('notifPrefs', notifPrefs);
        }
      }
    },
    [],
  );

  const changeEmail = useCallback(
    (event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
      const emailValue = event.target.value;

      setValue('email', emailValue);

      if (emailValue) {
        let notifPrefs = getValues('notifPrefs');

        if (
          notifPrefs &&
          Array.isArray(notifPrefs) &&
          !notifPrefs.includes('email')
        ) {
          notifPrefs = [...notifPrefs, 'email'];
        }

        setValue('notifPrefs', notifPrefs);
      }

      if (emailValue === '') {
        let notifPrefs = getValues('notifPrefs');
        if (
          notifPrefs &&
          Array.isArray(notifPrefs) &&
          notifPrefs.includes('email')
        ) {
          notifPrefs = notifPrefs.filter(p => p !== 'email');
          setValue('notifPrefs', notifPrefs);
        }
      }
    },
    [],
  );

  const handleChange = (
    event: React.ChangeEvent<HTMLInputElement>,
    checked: boolean,
  ) => {
    setUseMetric(checked);
  };

  const confirmRemove = () => {
    if (rider) {
      removeCarePartner({ variables: { riderId: rider.id } })
        .then(result => {
          if (result && getRiderProfile) {
            getRiderProfile();
            setOpenConfirmRemoveCarePartner(false);
            setShowCarePartner(false);
          }
        })
        .catch(e => {
          console.log('eeeee', e);
        });
    }
  };

  const changeHeightValue = (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
    onChange: (...event: any[]) => void,
    currentValue: any,
  ) => {
    if (e.target.value === '') {
      onChange(e.target.value);
      return;
    }

    if (!Number.isInteger(+e.target.value)) {
      onChange(currentValue || '');
      return;
    }

    if (+e.target.value < 0) {
      return;
    } else {
      onChange(+e.target.value);
    }
  };

  return (
    <>
      {updateError && (
        <Alert severity="error">
          There was an unexpected error updating the rider profile. Please wait
          a minute and then try again.
        </Alert>
      )}
      <form noValidate onSubmit={handleSubmit(submit)}>
        <Box className={classes.avatarUpload}>
          {/*@ts-ignore */}
          <AvatarUpload person={rider} register={register} />
        </Box>
        <SectionHeader title="General Information" />
        <Grid container spacing={2} className={classes.section}>
          <Grid item xs={12} sm={6}>
            <TextField
              required
              fullWidth
              id="firstName"
              name="firstName"
              label="First Name"
              defaultValue={rider?.firstName || firstName || null}
              error={!!errors.firstName}
              helperText={errors.firstName && 'First Name is required'}
              inputRef={register({
                required: true,
              })}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              required
              id="lastName"
              fullWidth
              name="lastName"
              label="Last Name"
              defaultValue={rider?.lastName || lastName || null}
              error={!!errors.lastName}
              helperText={errors.lastName && 'Last Name is required'}
              inputRef={register({
                required: true,
              })}
            />
          </Grid>

          <Grid item xs={12} sm={6}>
            <TextField
              id="phone"
              fullWidth
              name="phone"
              label="Phone"
              defaultValue={rider?.phone || null}
              error={!!errors.phone}
              helperText={phoneHelperText()}
              inputRef={register({
                validate: validatePhone,
              })}
              onChange={changePhone}
            />
          </Grid>

          <Grid item xs={12} sm={6}>
            <TextField
              id="email"
              fullWidth
              name="email"
              label="Email"
              defaultValue={rider?.email || null}
              error={!!errors.email}
              helperText={emailHelperText()}
              inputRef={register({
                validate: validateEmail,
              })}
              onChange={changeEmail}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <Controller
              defaultValue={rider?.dob || dob || null}
              control={control}
              name="dob"
              rules={{
                validate: {
                  validDate: value =>
                    !value ||
                    isValidDate(value, 'MM/DD/YYYY') ||
                    'Date must be valid',
                  notFutureDate: value =>
                    !value ||
                    moment(value).isSameOrBefore(moment()) ||
                    'Date must not be in the future',
                },
              }}
              render={({ ref, value, ...rest }) => {
                return (
                  <KeyboardDatePicker
                    format="MM/DD/YYYY"
                    fullWidth
                    id="dob"
                    inputRef={register()}
                    autoOk
                    label="DOB"
                    disableFuture
                    value={value ?? null}
                    error={!!errors.dob}
                    helperText={errors.dob && errors.dob.message}
                    InputProps={{
                      onBlur: () => {
                        trigger('dob');
                      },
                    }}
                    {...rest}
                  />
                );
              }}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <InputLabel id="select-language">Language</InputLabel>
            <Controller
              control={control}
              name="languageId"
              rules={{ required: true }}
              defaultValue={rider?.languageId || languages?.[0].id || '1'}
              as={
                <Select
                  required
                  style={{ width: '100%' }}
                  defaultValue={languages?.[0].id}
                  labelId="language-selector"
                  id="languageSelector"
                >
                  {languages?.map(language => {
                    return (
                      <MenuItem key={language.id} value={language.id}>
                        {language.displayName}
                      </MenuItem>
                    );
                  })}
                </Select>
              }
            />
            {errors.language && (
              <FormHelperText error>Language is required</FormHelperText>
            )}
          </Grid>
          <Grid item xs={12} sm={6}>
            <InputLabel id="select-gender">Gender</InputLabel>
            <Controller
              control={control}
              name="gender"
              rules={{ required: false }}
              defaultValue={rider?.gender}
              as={
                <Select
                  required
                  style={{ width: '100%' }}
                  labelId="gender-selector"
                  id="genderSelector"
                >
                  {genders?.map(gender => {
                    return (
                      <MenuItem key={gender.id} value={gender.id}>
                        {gender.displayName}
                      </MenuItem>
                    );
                  })}
                </Select>
              }
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <Controller
              control={control}
              name="address"
              rules={{ required: false }}
              defaultValue={rider?.address || null}
              render={({ onChange, ...props }) => {
                return (
                  <GooglePlacesAutocomplete
                    value={props?.value}
                    homeAddress={null}
                    suggestedLocations={[]}
                    id="address"
                    inputStyle={[]}
                    label="Home Address"
                    inputError={!!errors.address}
                    onChange={onChange}
                    onInputChange={() => {}}
                    helperText={''}
                  />
                );
              }}
            />
          </Grid>
          <Grid data-testid="rider notify" item xs={12} sm={12}>
            <Typography variant="body1">
              The rider's preferred method(s) of communication with the Onward
              team.
            </Typography>
            <Controller
              control={control}
              name="notifPrefs"
              defaultValue={rider?.notifPrefs || []}
              render={props => (
                // @ts-ignore
                <NotifPrefSelector
                  onChange={props.onChange}
                  value={props.value}
                />
              )}
            />
          </Grid>

          <Grid style={{ paddingLeft: 8, marginTop: '1rem' }} xs={12}>
            <MetricSwitch checked={useMetric} handleChange={handleChange} />
          </Grid>

          <Grid item xs={12} sm={useMetric ? 6 : 4}>
            <Controller
              name="weight"
              control={control}
              rules={{
                validate: data => {
                  if (data !== undefined && data !== null && data !== 0) {
                    if (data < 0) {
                      return false;
                    }
                    const max = useMetric ? getKgFromLBS(maxWeight) : maxWeight;
                    if (data >= max) {
                      return false;
                    }
                  }
                  return undefined;
                },
              }}
              render={props => {
                return (
                  <>
                    <TextField
                      fullWidth
                      id="weight"
                      value={props.value}
                      label={<span>Weight ({useMetric ? 'kg' : 'lbs'})</span>}
                      onChange={e => {
                        if (e.target.value === '') {
                          props.onChange(e.target.value);
                          return;
                        }

                        if (!Number.isInteger(+e.target.value)) {
                          props.onChange(weight || 0);
                          return;
                        }

                        if (+e.target.value < 0) {
                          return;
                        } else {
                          props.onChange(+e.target.value);
                        }
                      }}
                    />
                    {Boolean(weight) &&
                      +weight >
                        (useMetric ? getKgFromLBS(maxWeight) : maxWeight) && (
                        <FormHelperText error>
                          Please call 1-800-700-4797
                        </FormHelperText>
                      )}
                  </>
                );
              }}
            />
          </Grid>

          <Grid
            style={{
              visibility: useMetric ? 'hidden' : 'visible',
              width: useMetric ? 0 : 'auto',
              padding: useMetric ? 0 : 8,
            }}
            item
            xs={12}
            sm={useMetric ? true : 4}
          >
            <Controller
              name="heightFeet"
              control={control}
              render={props => {
                return (
                  <TextField
                    value={props.value ?? ''}
                    fullWidth
                    id="heightFeet"
                    label={<span>Height (ft)</span>}
                    onChange={e =>
                      changeHeightValue(e, props.onChange, heightFeet)
                    }
                  />
                );
              }}
            />
          </Grid>
          <Grid
            style={{
              visibility: useMetric ? 'hidden' : 'visible',
              width: useMetric ? 0 : 'auto',
              padding: useMetric ? 0 : 8,
            }}
            item
            xs={12}
            sm={useMetric ? true : 4}
          >
            <Controller
              name="heightInch"
              control={control}
              render={props => {
                return (
                  <TextField
                    value={props.value ?? ''}
                    fullWidth
                    id="heightInch"
                    label={<span>Height (in)</span>}
                    onChange={e =>
                      changeHeightValue(e, props.onChange, heightInch)
                    }
                  />
                );
              }}
            />
          </Grid>

          <Grid
            style={{
              visibility: !useMetric ? 'hidden' : 'visible',
              width: !useMetric ? 0 : 'auto',
              padding: !useMetric ? 0 : 8,
            }}
            item
            xs={12}
            sm={!useMetric ? true : 6}
          >
            <Controller
              name="heightCm"
              control={control}
              render={props => {
                return (
                  <TextField
                    fullWidth
                    value={props.value ?? ''}
                    id="heightCm"
                    label={<span>Height (cm)</span>}
                    onChange={e =>
                      changeHeightValue(e, props.onChange, heightCm)
                    }
                  />
                );
              }}
            />
          </Grid>

          <Grid item xs={12}>
            <TextField
              id="notes"
              fullWidth
              name="notes"
              label="Special Considerations"
              defaultValue={rider?.notes || null}
              multiline
              rows={3}
              helperText={
                'Conditions, accessibility issues, or other things the driver should know about?'
              }
              inputRef={register()}
            />
          </Grid>
        </Grid>

        <div
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
          }}
        >
          <SectionHeader title="Care Partner" />
          {rider?.carePartner ? (
            <Button
              onClick={tryRemove}
              key="updateButton"
              variant="contained"
              color="secondary"
            >
              Remove Care Partner
            </Button>
          ) : (
            <Button
              onClick={() => setShowCarePartner(!showCarePartner)}
              key="updateButton"
              variant="contained"
              color="secondary"
            >
              {showCarePartner ? 'Hide Care Partner Form' : 'Add Care Partner'}
            </Button>
          )}
        </div>
        {showCarePartner ? (
          <Grid container spacing={2} className={classes.section}>
            <Grid item xs={12} sm={6}>
              <TextField
                fullWidth
                id="partnerFirstName"
                name="carePartner.firstName"
                label="First Name"
                defaultValue={rider?.carePartner?.firstName || null}
                error={!!errors.carePartner?.firstName}
                helperText={
                  errors.carePartner?.firstName && 'First Name is required'
                }
                inputRef={register({
                  required: !!carePartner?.lastName,
                })}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                id="partnerLastName"
                fullWidth
                name="carePartner.lastName"
                label="Last Name"
                defaultValue={rider?.carePartner?.lastName || null}
                error={!!errors.carePartner?.lastName}
                helperText={
                  errors.carePartner?.lastName && 'Last Name is required'
                }
                inputRef={register({
                  required: !!carePartner?.firstName,
                })}
              />
            </Grid>

            <Grid item xs={12} sm={6}>
              <TextField
                id="partnerPhone"
                disabled={disabledPartnerFields}
                fullWidth
                name="carePartner.phone"
                label="Phone"
                defaultValue={rider?.carePartner?.phone || null}
                error={!!errors.carePartner?.phone}
                helperText={!!errors.carePartner?.phone && 'Phone is required'}
                inputRef={register({
                  required: requiredPartnerFields,
                })}
              />
            </Grid>

            <Grid item xs={12} sm={6}>
              <TextField
                id="partnerEmail"
                disabled={disabledPartnerFields}
                fullWidth
                name="carePartner.email"
                label="Email"
                defaultValue={rider?.carePartner?.email || null}
                error={!!errors.carePartner?.email}
                helperText={!!errors.carePartner?.email && 'Email is required'}
                inputRef={register({
                  validate: validatePartnerEmail,
                })}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <InputLabel
                error={!!errors.carePartner?.relationshipType}
                id="select-relationship"
              >
                Relationship
              </InputLabel>
              <Controller
                disabled={disabledPartnerFields}
                control={control}
                name="carePartner.relationshipType"
                rules={{
                  required: requiredPartnerFields,
                }}
                defaultValue={rider?.carePartner?.relationshipType || ''}
                as={
                  <Select
                    error={!!errors.carePartner?.relationshipType}
                    style={{ width: '100%' }}
                    labelId="relationship-selector"
                    id="relationshipSelector"
                  >
                    <MenuItem value="">None</MenuItem>
                    {Object.entries(CarePartnerRelationship).map(item => {
                      return (
                        <MenuItem key={item[0]} value={item[1]}>
                          {capitalizeFirstLetter(
                            item[1].toLowerCase().split('_').join(' '),
                          )}
                        </MenuItem>
                      );
                    })}
                  </Select>
                }
              />
              {errors.carePartner?.relationshipType && (
                <FormHelperText error>Relationship Required</FormHelperText>
              )}
            </Grid>

            <Grid item xs={12} sm={6}>
              <TextField
                id="notes"
                fullWidth
                name="carePartner.relationshipNotes"
                label="Relationship Notes"
                disabled={disabledPartnerFields}
                defaultValue={rider?.carePartner?.relationshipNotes || null}
                multiline
                error={!!errors.carePartner?.relationshipNotes}
                helperText={
                  !!errors.carePartner?.relationshipNotes &&
                  'Relationship Notes Required'
                }
                rows={1}
                inputRef={register({
                  required:
                    carePartner?.relationshipType ===
                    CarePartnerRelationship.Other,
                })}
              />
            </Grid>

            <Grid item xs={12}>
              <Typography
                style={
                  errors?.carePartner?.notifPrefs?.message
                    ? { color: onwardColors.onwardRed }
                    : {}
                }
                variant="body1"
              >
                The partner's preferred method(s) of communication with the
                Onward team.
              </Typography>
              <div
                style={
                  disabledPartnerFields
                    ? {
                        pointerEvents: 'none',
                        opacity: 0.7,
                      }
                    : {}
                }
              >
                <Controller
                  control={control}
                  name="carePartner.notifPrefs"
                  rules={{
                    validate: data => {
                      if (requiredPartnerFields && data.length === 0) {
                        return 'Communication Method is Required';
                      }

                      return undefined;
                    },
                  }}
                  defaultValue={rider?.carePartner?.notifPrefs || []}
                  render={props => (
                    // @ts-ignore
                    <NotifPrefSelector
                      onChange={props.onChange}
                      value={props.value}
                    />
                  )}
                />

                {errors?.carePartner?.notifPrefs?.message && (
                  <FormHelperText error>
                    Communication Method is Required
                  </FormHelperText>
                )}
              </div>
            </Grid>
          </Grid>
        ) : null}

        <SectionHeader title="Assistance / Impairments" />

        <Grid container spacing={2} className={classes.section}>
          <Grid item xs={12} sm={6} className={classes.impairmentGridItem}>
            <ImpairmentInput
              control={control}
              label="Mobility"
              name="mobilityAssistance"
              question="What level of mobility assistance does the rider need?"
              options={[
                ['none', 'None'],
                ['arm', 'Needs an arm for support'],
                ['walker', 'Uses a walker or cane'],
                [
                  'wheelchair',
                  'Uses a wheelchair, but can walk short distances',
                ],
                [
                  'wheelchair_with_lift',
                  'Uses a wheelchair and needs support to stand and pivot into the vehicle',
                ],
              ]}
              defaultValue={rider?.mobilityAssistance || 'none'}
              Icon={AccessibleIcon}
            />
          </Grid>
          <Grid item xs={12} sm={6} className={classes.impairmentGridItem}>
            <ImpairmentInput
              control={control}
              label="Vision"
              name="visionImpairment"
              question="Does the rider have any vision impairment?"
              options={[
                ['none', 'None'],
                ['mild', 'Mild'],
                ['medium', 'Medium'],
                ['severe', 'Severe'],
              ]}
              defaultValue={rider?.visionImpairment || 'none'}
              Icon={VisibilityIcon}
            />
          </Grid>
          <Grid item xs={12} sm={6} className={classes.impairmentGridItem}>
            <ImpairmentInput
              control={control}
              label="Hearing"
              name="hearingImpairment"
              question="Does the rider have any hearing impairment?"
              options={[
                ['none', 'None'],
                ['mild', 'Mild'],
                ['medium', 'Medium'],
                ['severe', 'Severe'],
              ]}
              defaultValue={rider?.hearingImpairment || 'none'}
              Icon={HearingIcon}
            />
          </Grid>
          <Grid item xs={12} sm={6} className={classes.impairmentGridItem}>
            <ImpairmentInput
              control={control}
              label="Cognitive"
              name="cognitiveImpairment"
              question="Does the rider have any cognitive impairment?"
              options={[
                ['none', 'None'],
                ['mild', 'Mild'],
                ['medium', 'Medium'],
                ['severe', 'Severe'],
              ]}
              defaultValue={rider?.cognitiveImpairment || 'none'}
              Icon={EmojiObjectsIcon}
            />
          </Grid>
        </Grid>

        <SectionHeader title="Alterations" />
        <Box className={classes.section}>
          <ImpairmentInput
            control={control}
            name="canMakeChanges"
            label="Alterations"
            question={`Is ${
              rider?.firstName || 'the rider'
            } allowed to make changes to the ride?`}
            options={[
              [
                true,
                `Yes, ${rider?.firstName || 'the rider'} can make changes`,
              ],
              [false, `No, All changes should be run by a coordinator`],
            ]}
            defaultValue={!!rider?.canMakeChanges}
            Icon={VisibilityIcon}
          />
        </Box>

        <Button
          key="updateButton"
          type="submit"
          variant="contained"
          color="primary"
          className={classes.submit}
        >
          {newProfile ? 'Save and Continue' : 'Update Profile'}
        </Button>
      </form>

      <Dialog
        maxWidth="xs"
        aria-labelledby="confirmation-dialog-title"
        open={openConfirmRemoveCarePartner}
      >
        <DialogTitle id="confirmation-dialog-title">
          Remove Care Partner?
        </DialogTitle>
        <DialogActions>
          <Button
            disabled={loading}
            onClick={() => setOpenConfirmRemoveCarePartner(false)}
            color="primary"
          >
            Cancel
          </Button>
          <Button disabled={loading} onClick={confirmRemove} color="primary">
            Remove
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};
