import * as Sentry from '@sentry/react';

let reportErrors = false;

function init(shouldReportErrors, sentryDsn, environment) {
  reportErrors = shouldReportErrors;

  if (reportErrors) {
    Sentry.init({
      dsn: sentryDsn,
      environment: environment || 'development',
    });
  }
}

function captureException(error) {
  console.error(error);
  if (reportErrors) {
    Sentry.captureException(error instanceof Error ? error : new Error(error));
  }
}

function captureExceptionWithScope(error, errorInfo) {
  console.error(error);
  if (reportErrors) {
    Sentry.withScope(scope => {
      Object.keys(errorInfo).forEach(key => {
        scope.setExtra(key, errorInfo[key]);
      });
      Sentry.captureException(error);
    });
  }
}

function captureMessage(message) {
  console.log(message);
  if (reportErrors) {
    Sentry.captureMessage(message);
  }
}

const errorHandler = {
  init,
  captureMessage,
  captureException,
  captureExceptionWithScope,
};

export default errorHandler;
