import {
  Avatar,
  Box,
  Card,
  CardActionArea,
  CardContent,
  CardMedia,
  Tooltip,
  Typography,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import EmojiObjectsIcon from '@material-ui/icons/EmojiObjects';
import HearingIcon from '@material-ui/icons/Hearing';
import VisibilityIcon from '@material-ui/icons/Visibility';
import { impairmentSummary } from '@onwardcare/core/lib/utils/impairment-utils';
import { mobilitySummary } from '@onwardcare/core/lib/utils/mobility-utils';
import {
  personInitials,
  personName,
} from '@onwardcare/core/lib/utils/name-utils';
import { formatPhoneNumber } from '@onwardcare/core/lib/utils/phone-utils';
import ENV from 'constants/Env';
import React from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { ElderlyIcon } from '../icons/icons';

const useStyles = makeStyles(theme => ({
  root: {
    height: '100%',
  },
  media: {
    height: 140,
    backgroundColor: theme.palette.action.disabledBackground,
  },
  bio: {
    maxHeight: 40,
    overflow: 'hidden',
  },
  avatar: {
    height: 120,
    width: 120,
    backgroundColor: '#fff',
    color: theme.palette.text.primary,
  },
}));

const RiderImpairments = ({ rider }) => {
  const classes = useStyles();

  return (
    <Box flexGrow={1}>
      {rider.mobilityAssistance !== 'none' && (
        <Tooltip
          title={mobilitySummary(rider.mobilityAssistance)}
          aria-label="mobility assistance"
        >
          <ElderlyIcon />
        </Tooltip>
      )}
      {rider.visionImpairment !== 'none' && (
        <Tooltip
          title={
            impairmentSummary(rider.visionImpairment) + ' vision impairment'
          }
          aria-label="vision impairment"
        >
          <VisibilityIcon className={classes.riderIcon} />
        </Tooltip>
      )}
      {rider.hearingImpairment !== 'none' && (
        <Tooltip
          title={
            impairmentSummary(rider.hearingImpairment) + ' hearing impairment'
          }
          aria-label="hearing impairment"
        >
          <HearingIcon className={classes.riderIcon} />
        </Tooltip>
      )}
      {rider.cognitiveImpairment !== 'none' && (
        <Tooltip
          title={
            impairmentSummary(rider.cognitiveImpairment) +
            ' cognitive impairment'
          }
          aria-label="cognitive impairment"
        >
          <EmojiObjectsIcon className={classes.riderIcon} />
        </Tooltip>
      )}
    </Box>
  );
};

const RiderCard = ({ rider }) => {
  const classes = useStyles();

  return (
    <Card className={classes.root}>
      <CardActionArea component={RouterLink} to={`/riders/${rider.id}`}>
        {rider.profilePhoto ? (
          <CardMedia
            className={classes.media}
            image={ENV.apiUrl + rider.profilePhoto}
            title={personName(rider)}
          />
        ) : (
          <CardMedia className={classes.media}>
            <Box
              display="flex"
              flexDirection="column"
              justifyContent="center"
              alignItems="center"
              height="100%"
            >
              <Avatar className={classes.avatar}>
                {personInitials(rider)}
              </Avatar>
            </Box>
          </CardMedia>
        )}

        <CardContent>
          <Typography variant="h5" component="h2">
            {personName(rider)}
          </Typography>
          {rider.phone && (
            <Typography variant="subtitle2">
              {formatPhoneNumber(rider.phone)}
            </Typography>
          )}
          <RiderImpairments rider={rider} />
          <Typography
            variant="body2"
            color="textSecondary"
            component="p"
            className={classes.bio}
          >
            {rider.notes}
          </Typography>
        </CardContent>
      </CardActionArea>
    </Card>
  );
};

export default RiderCard;
