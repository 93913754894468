import { useApolloClient, useQuery } from '@apollo/client';
import {
  Box,
  Button,
  CircularProgress,
  TextField,
  Typography,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { Alert, AlertTitle, Autocomplete } from '@material-ui/lab';
import { personName } from '@onwardcare/core/lib/utils/name-utils';
import { useAuth } from '../contexts/auth-context';
import LayoutWithQuery from 'components/layout/LayoutWithQuery.tsx';
import { loader } from 'graphql.macro';
import { useTracking } from 'lib/analytics/Tracker';
import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import {
  accountCustodianStorage,
  historyStorage,
  ridersStorage,
  upcomingRidesStorage,
} from '../storages/storage';

const PROFILE_QUERY = loader('../data/queries/ProfileQuery.graphql');

const CUSTODIANS_QUERY = loader('../data/queries/CustodiansQuery.graphql');

const useStyles = makeStyles(theme => ({
  header: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(3),
  },
  submit: {
    width: 300,
    marginTop: theme.spacing(3),
  },
}));

function MagicComponent({ onBecome }) {
  const classes = useStyles();
  const [becomeUser, setBecomeUser] = useState(null);
  const [query, setQuery] = React.useState('');

  const { loading, refetch, data } = useQuery(CUSTODIANS_QUERY, {
    variables: { query },
    skip: query === '',
  });

  useEffect(() => {
    refetch({ variables: { query } });
  }, [refetch, query]);

  return (
    <div className="App">
      <header className={classes.header}>
        <Typography variant="h4" gutterBottom>
          Magic
        </Typography>
        <Typography variant="body1">
          This lets you as an admin "become" another user. This is a great
          power, so with it comes great responsibility. Be very careful!
        </Typography>
      </header>

      <Autocomplete
        filterOptions={(options, _other) => options}
        id="magic-autocomplete"
        style={{ width: 300 }}
        getOptionSelected={(option, value) =>
          option.firstName === value.firstName
        }
        getOptionLabel={option => option.firstName}
        options={data?.custodians || []}
        loading={loading}
        onInputChange={(event, newInputValue) => {
          setQuery(newInputValue);
        }}
        onChange={(e, v) => {
          setBecomeUser(v);
        }}
        renderOption={option => {
          return (
            <Box>
              <Typography variant="body1">{personName(option)}</Typography>
              <Typography variant="body2" color="textSecondary">
                {option.email}
              </Typography>
            </Box>
          );
        }}
        renderInput={params => (
          <TextField
            {...params}
            label="Select Custodian"
            variant="outlined"
            InputProps={{
              ...params.InputProps,
              endAdornment: (
                <React.Fragment>
                  {loading ? (
                    <CircularProgress color="inherit" size={20} />
                  ) : null}
                  {params.InputProps.endAdornment}
                </React.Fragment>
              ),
            }}
          />
        )}
      />

      <Button
        key="submitButton"
        type="submit"
        variant="contained"
        color="primary"
        className={classes.submit}
        disabled={!becomeUser}
        onClick={() => {
          onBecome(becomeUser);
        }}
      >
        Becomeify!
      </Button>
    </div>
  );
}

function MagicContainer() {
  const auth = useAuth();
  const tracker = useTracking();
  const apolloClient = useApolloClient();
  const history = useHistory();

  useEffect(() => {
    tracker.track('Magic Viewed');
  }, [tracker]);

  const onBecome = becomeCustodian => {
    tracker.track('Became Custodian', { became: becomeCustodian.email });
    auth.storeBecomeCustodian(apolloClient, becomeCustodian);
    tracker.reset();
    tracker.identify(becomeCustodian.email);
    ridersStorage.clearStorage();
    upcomingRidesStorage.clearStorage();
    historyStorage.clearStorage();
    accountCustodianStorage.clearStorage();
    history.replace({ pathname: '/' });
  };

  return (
    <LayoutWithQuery
      query={PROFILE_QUERY}
      renderView={data => {
        if (!data.profile.admin) {
          return (
            <Alert severity="error">
              <AlertTitle>Error</AlertTitle>
              Sorry, you don't have permission to be here
            </Alert>
          );
        }
        return <MagicComponent onBecome={onBecome} />;
      }}
    />
  );
}

export default MagicContainer;
