import { useLazyQuery } from '@apollo/client';
import {
  Box,
  Button,
  Checkbox,
  Divider,
  FormControl,
  FormControlLabel,
  FormGroup,
  FormHelperText,
  Grid,
  Input,
  InputLabel,
  Link,
  ListItemAvatar,
  ListItemText,
  MenuItem,
  Paper,
  Select,
  Switch,
  TextField,
  Tooltip,
  Typography,
} from '@material-ui/core';
import Autocomplete from '@material-ui/lab/Autocomplete';
import AddIcon from '@material-ui/icons/Add';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import CreditCardIcon from '@material-ui/icons/CreditCard';
import InfoIcon from '@material-ui/icons/Info';
import { Alert } from '@material-ui/lab';
import { DatePicker } from '@material-ui/pickers';
import moment, { Moment } from 'moment-timezone';
import { personName } from '@onwardcare/core/lib/utils/name-utils';
import GooglePlacesAutocomplete from 'components/GooglePlacesAutocomplete';
import { LabelWithTooltip } from 'components/LabelWithTooltip';
import Map from 'components/Map';
import PersonImage from 'components/PersonImage';
import RideEstimate from 'components/rides/RideEstimate';
import onwardColors from 'lib/onwardColors';
import { FC, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import {
  Link as RouterLink,
  useHistory,
  useLocation,
  useParams,
} from 'react-router-dom';
import * as stripeJs from '@stripe/stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import { Elements } from '@stripe/react-stripe-js';
import { StripeForm } from '../layout/StripeForm';
import { RideFormType, RideFormTypeSubmit } from 'pages/types';
import { validPhone } from '@onwardcare/core/lib/validation/form-validation';
import type {
  Exact,
  FinancialResponsibilityChoice,
  Ride,
  Rider,
  ScheduleQueryWithRideQuery,
} from 'generated/graphql';
import {
  QueryRiderArgs,
  CreateMncsInput,
  CreateRecurringInput,
  FieldError,
  Maybe,
  PaymentMethod,
  QueryAccountFinancialResponsibilityChoicesArgs,
  StripeSetupIntentQueryDocument,
  useRideSuggestedLocationsQueryLazyQuery,
  useRideNotesPlaceholderTextQueryLazyQuery,
  useRidersQueryLazyQuery,
} from 'generated/graphql';
import type { ApolloQueryResult } from '@apollo/client/core/types';
import { Stripe } from '@stripe/stripe-js/types/stripe-js';
import { loader } from 'graphql.macro';
import isEmpty from 'lodash/isEmpty';
import debounce from 'lodash/debounce';
import { TransportTypeOptions } from './rideFormComponents';
import {
  TransportTypeFeeOptionsComponent,
  useTransportTypeOptions,
} from './rideFormComponents/TransportTypeComponent';
import { AddMedicalNecessityStatement } from './rideFormComponents/AddMedicalNecessityStatement/AddMedicalNecessityStatement';
import { useStyles } from './rideFormStyles';
import FinancialResponsibilityForm from './rideFormComponents/financialResponsibility/FinancialResponsibilityForm';
import { BedSideNurseComponent } from './rideFormComponents/BedSideComponent/BedSideNurseComponent';
import { TransportTypeSelector } from './rideFormComponents/TransportTypeSelector/TransportTypeSelector';
import HelpInformation from 'components/HelpInformation';
import SessionInformation from 'components/SessionInformation';
import {
  HOUR_FORMAT_24,
  fullNumericDate,
  momentFromTimeString,
} from 'lib/TimeUtils';
import { RideshareComponents } from './rideFormComponents/Rideshare';
import { useVetridesContext } from '../../contexts/vetrides-context';
import { INCHES_IN_CM } from './rideFormComponents/constants';
import {
  getCmFromInches,
  getKgFromLBS,
  getLBSFromKg,
} from './rideFormComponents/helpers';
import { TransportReasonCategories } from './rideFormComponents/TransportReasonCategories/TransportReasonCategories';
import { ConfirmNotifyDialog } from './rideFormComponents/ConfirmDialogNotify/ConfirmNotifyDialog';
import ZeroStateText from '../ZeroStateText';
import { useEhrContext } from '../../contexts/ehr-context';
import { RideTimeHelper } from './rideFormComponents/RideTimeHelper/RideTimeHelper';
import { readIsMetric } from '../../storages/is-metric-storage';
import { useRiderProfileNavigation } from '../../hooks/useRiderProfileNavigation';
import {
  deleteSession,
  readSessionObject,
  writeSessionObject,
} from '../../storages/session-storage';
import { LocationPhoto } from './LocationPhoto';

const RIDER_PROFILE_QUERY_2 = loader(
  '../../data/queries/RiderProfileQuery.graphql',
);

const DEFAULT_LATITUDE = 37.84073;
const DEFAULT_LONGITUDE = -122.251691;
const FORM_KEY = 'transportationForm';

/**
 * Delete the saved form data from the session storage.
 */
const deleteSavedFormData = () => {
  deleteSession(FORM_KEY);
};
/**
 * Read the saved form data from the session storage.
 *
 * @returns The saved form data from the session storage.
 */
const readSavedFormData = () => {
  return readSessionObject(FORM_KEY) as Record<string, any> | null;
};
/**
 * Write the form data to the session storage.
 *
 * @param data The form data to write to the session storage.
 */
const writeFormData = (data: object) => {
  writeSessionObject(FORM_KEY, data);
};

const rideTypes = [
  { type: 'oneway', text: 'One Way' },
  { type: 'roundtrip', text: 'Wait and Return' },
  { type: 'delivery', text: 'Delivery' },
];

const defaultRiderPaymentMethodId = ({
  rider,
  account,
  defaultRide = null,
}: {
  rider: Rider;
  account: ScheduleQueryWithRideQuery['account'];
  defaultRide?: ScheduleQueryWithRideQuery['ride'];
}) => {
  // if there is a default ride it should either have a
  // payment method or be invoiced
  if (defaultRide) {
    if (defaultRide.paymentMethod) {
      return defaultRide.paymentMethod.id;
    }
    if (account?.invoiced) {
      return 'invoice';
    }
  }

  if (account?.invoiced) {
    return 'invoice';
  } else {
    return account?.invoiced
      ? 'invoice'
      : rider.paymentMethods?.[0]?.id ||
          account?.paymentMethods?.[0]?.id ||
          null;
  }
};

const trueAddress = (e: any) => {
  return !!e['zipcode'];
};

const defaultStartLocation = (defaultRide?: Ride) => {
  if (defaultRide === undefined) {
    return null;
  }

  const { startLocationPhoto } = defaultRide;
  const { ...startLocationFields } = defaultRide.startLocation;
  return {
    ...startLocationFields,
    locationPhoto: startLocationPhoto,
  };
};

const defaultEndLocation = (defaultRide?: Ride) => {
  if (defaultRide === undefined) {
    return null;
  }

  const { endLocationPhoto } = defaultRide;
  const { ...endLocationFields } = defaultRide.endLocation;
  return {
    ...endLocationFields,
    locationPhoto: endLocationPhoto,
  };
};

export type RideFormProps = {
  rideErrorsState?: Maybe<Array<FieldError>>;
  createError: string;
  onSubmit: (data: RideFormType) => void;
  admin: boolean;
  account: ScheduleQueryWithRideQuery['account'];
  profile: ScheduleQueryWithRideQuery['profile'];
  riders: ScheduleQueryWithRideQuery['riders'];
  defaultRide: Ride;
  recurringOptions?: Maybe<CreateRecurringInput>;
  defaultRiderId?: string;
  isSubmitting: boolean;
  actionType: 'update' | 'create';
  useDefaultRideRequestedStartTime?: boolean;
  getAccountData?:
    | ((
        variables?: Partial<Exact<{ rideId: number }>> | undefined,
      ) => Promise<ApolloQueryResult<ScheduleQueryWithRideQuery>>)
    | undefined;
  isReturnTrip?: boolean;
  mncsDefaultAnswers?: Record<string, any>;
};

const DROPOFF_START_TIME_DIFF = 30;
const REQUESTED_START_GAP_MINUTES = 60;

export const RideForm: FC<RideFormProps> = ({
  createError,
  onSubmit,
  admin,
  account,
  profile,
  riders,
  defaultRide,
  defaultRiderId: defaultRiderIdProp,
  isSubmitting,
  actionType,
  useDefaultRideRequestedStartTime,
  getAccountData,
  isReturnTrip,
  recurringOptions,
  rideErrorsState,
  mncsDefaultAnswers,
}) => {
  const classes = useStyles();
  const history = useHistory();
  const { isEhrPatientContext } = useEhrContext();
  const { isVetride } = useVetridesContext();
  const { generateFromParams } = useRiderProfileNavigation();

  const showSessionInfo = isEhrPatientContext || isVetride;

  const { search } = useLocation();
  const [bufferTime, setBufferTime] = useState<number | undefined>(undefined);

  const isMetric = readIsMetric();
  const [useMetric, setUseMetric] = useState(isMetric);

  const [ridersList, setRidersList] = useState<Rider[] | null | undefined>(
    riders,
  );
  const riderId = defaultRiderIdProp || defaultRide?.rider?.id;
  // Pulling the riderId from the URL query params or the route params is only
  // used for when to clear the saved form data. Using this instead of the
  // `riderId` variable in the line above will make sure we don't use the rider
  // id when they are loading a Vetrides request. Otherwise, it will not
  // properly delete the old saved data and you'll get bad data in the form.
  const urlParams = new URLSearchParams(search);
  const riderIdQueryParam = urlParams.get('riderID');
  const { riderId: riderIdRouteParam } = useParams<{
    riderId?: string;
  }>();
  const riderIdParam = riderIdQueryParam || riderIdRouteParam;

  // This is probably used for consumer accounts that only have one rider.
  const defaultRider =
    ridersList && ridersList.length === 1 ? ridersList[0] : null;
  // This style is used to either show or hide the cost center field if there is
  // only one cost center available.
  const [singleCostCenterStyle, setSingleCostCenterStyle] = useState(
    'inline-flex',
  );

  const [getRider] = useLazyQuery<any, QueryRiderArgs>(RIDER_PROFILE_QUERY_2, {
    onCompleted: theData => {
      setRidersList(
        ridersList ? [...ridersList, theData.rider] : [theData.rider],
      );
    },
  });

  const transportationForm = readSavedFormData() || {};

  const [getSetupIntent, { data: stripeSetupIntentData }] = useLazyQuery(
    StripeSetupIntentQueryDocument,
    {
      fetchPolicy: 'no-cache',
    },
  );

  const [stripeData, setStripeData] = useState(null);
  const [
    stripePromise,
    setStripePromise,
  ] = useState<Promise<Stripe | null> | null>(null);
  const [
    stripeOptions,
    setStripeOptions,
  ] = useState<stripeJs.StripeElementsOptions | null>(null);

  useEffect(() => {
    if (!riderIdParam) {
      // There is an outstanding issue with keeping the form data as long as
      // there is a `riderId` property. If the user fills out the form, then
      // leaves the page, then they go to the Rider Profile page and click the
      // "New Ride" button, it will have a `riderId` property, so it won't clear
      // the form data. That means that it will load the data from the previous
      // ride, which isn't what we want.
      //
      // Also, if you fill out the form, then click the "Add rider" or "Edit
      // rider" button, then come back, it will correctly populate the form. But
      // if you click one of those buttons again and come back, the form will
      // be back to empty.
      deleteSavedFormData();
    }
  }, [riderIdParam]);

  useEffect(() => {
    if (stripeSetupIntentData) {
      setStripeData(stripeSetupIntentData);
      setStripePromise(
        loadStripe(stripeSetupIntentData?.stripeSetupIntent?.publishableKey),
      );
      setStripeOptions({
        locale: 'en',
        clientSecret: stripeSetupIntentData?.stripeSetupIntent?.setupIntent,
        appearance: {
          theme: 'flat',
          variables: {
            borderRadius: '0px',
          },
        },
      });
    }
  }, [stripeSetupIntentData]);

  const handleClick = () => {
    if (account) {
      getSetupIntent({
        variables: { ownerId: account.id, ownerType: 'Account' },
      });
    }
  };

  const [showStartInstructions, setShowStartInstructions] = useState(false);
  const [showEndInstructions, setShowEndInstructions] = useState(false);
  const [requestedStartTime, setRequestedStartTime] = useState<Moment | null>(
    null,
  );
  const [requestedEndTime, setRequestedEndTime] = useState<Moment | null>(null);
  const [paymentMethodOptions, setPaymentMethodOptions] = useState<
    Array<PaymentMethod> | []
  >([]);
  const [promptForPayerDemographics, setPromptForPayerDemographics] = useState(
    false,
  );
  const [includeStartLocationPhoto, setIncludeStartLocationPhoto] = useState(
    true,
  );
  const [includeEndLocationPhoto, setIncludeEndLocationPhoto] = useState(true);

  const [
    shouldShowFulfillmentNotice,
    setShouldShowFulfillmentNotice,
  ] = useState(false);

  const ACCOUNT_FINANCIAL_RESPONSIBILITY_CHOICE_QUERY = loader(
    '../../data/queries/AccountFinancialResponsibilityChoicesQuery.graphql',
  );

  const [showRecurringOptions, setShowRecurringOptions] = useState(false);

  const toggleRecurringOptions = () => {
    setShowRecurringOptions(!showRecurringOptions);
  };

  let defaultPaymentMethodId = defaultRider
    ? defaultRiderPaymentMethodId({
        rider: defaultRider,
        account,
        defaultRide,
      })
    : null;

  const heightInchesSaved = transportationForm?.heightInches || 0;
  const weightSaved = transportationForm?.weightLbs;
  const heightInch =
    (riderId ? heightInchesSaved : defaultRide?.heightInches || 0) % 12;
  const heightFeet = Math.trunc(
    (riderId ? heightInchesSaved : defaultRide?.heightInches || 0) / 12,
  );
  const heightInches = riderId
    ? heightInchesSaved || 0
    : defaultRide?.heightInches || 0;
  const weightLbs = riderId ? weightSaved || 0 : defaultRide?.weightLbs || 0;

  const heightCm = getCmFromInches(heightInches);
  const fewAvailablePayerTypes =
    profile?.availablePayerTypes && profile.availablePayerTypes.length > 1;

  const availableTransportTypes =
    profile?.availableTransportTypes &&
    profile.availableTransportTypes.length >= 1
      ? profile.availableTransportTypes
      : [];

  const defaultTransportTypeName =
    availableTransportTypes.length >= 1 ? availableTransportTypes[0].name : '';
  const isUpdate = actionType === 'update';
  const isCreate = actionType === 'create';

  // default times should be in existing timezone if one exists
  const defaultValues = {
    ride: {
      rideType: defaultRide?.rideType || 'oneway',
      firstAvailable: defaultRide?.firstAvailable || false,
      riderId: riderId ? riderId : undefined,
      requestedStartTime:
        isUpdate || useDefaultRideRequestedStartTime === true
          ? moment(defaultRide?.requestedStartTime).tz(defaultRide?.tzName)
          : moment().add(2, 'hours'),
      startLocation: defaultStartLocation(defaultRide),
      endLocation: defaultEndLocation(defaultRide),
      passengerCount: defaultRide?.passengerCount || 1,
      transportType: defaultRide?.transportType || defaultTransportTypeName,
      flexibleRide: defaultRide?.flexibleRide || false,
      notes: defaultRide?.notes,
      deliverySubstitution: defaultRide
        ? defaultRide.deliverySubstitution
        : 'yes',
      paymentMethodId: defaultPaymentMethodId,
      bariatric: defaultRide?.bariatric,
      stairsCount: defaultRide?.stairsCount,
      weight: useMetric ? getKgFromLBS(weightLbs) : weightLbs,
      heightInch: heightInch,
      heightFeet: heightFeet,
      heightInches: heightInches,
      heightCm: heightCm,
      payerType: riderId
        ? transportationForm?.payerType
        : defaultRide?.payerType ||
          (fewAvailablePayerTypes && profile?.availablePayerTypes?.[0].name),
      patient: defaultRide?.payer,
      riderWillUseProvidersWheelchair: defaultRide?.riderWillUseProvidersWheelchair
        ? defaultRide.riderWillUseProvidersWheelchair
          ? 1
          : 0
        : 1,
      pickupRoomNumber: defaultRide?.pickupRoomNumber || null,
      dropoffRoomNumber: defaultRide?.dropoffRoomNumber || null,
      costCenter: riderId
        ? transportationForm?.costCenter || null
        : defaultRide?.costCenter || null,
      oxygenRequired: defaultRide?.oxygenRequired,
      contactPrecautionsRequired: defaultRide?.contactPrecautionsRequired,
      transportReason: defaultRide?.transportReason,
      transportReasonComment: defaultRide?.transportReasonComment,
      transportReasonAppointmentTime: defaultRide?.appointmentTime,
    },
    startTime:
      isUpdate || useDefaultRideRequestedStartTime === true
        ? moment(defaultRide?.requestedStartTime)
            .tz(defaultRide?.tzName)
            .format(HOUR_FORMAT_24)
        : moment().add(2, 'hours').startOf('hour').format(HOUR_FORMAT_24),
    endTime:
      isUpdate || useDefaultRideRequestedStartTime === true
        ? moment(defaultRide?.requestedEndTime)
            .tz(defaultRide?.tzName)
            .format(HOUR_FORMAT_24)
        : moment().add(3, 'hours').startOf('hour').format(HOUR_FORMAT_24),
    recurringOptions: recurringOptions || {
      interval: 1,
      repeat: 'weekly',
      untilDate: moment().add(4, 'w'),
      dow: null,
      notes: null,
    },
    mncsOptions: mncsDefaultAnswers || ({} as Record<string, any>),
    bedsideNurse: defaultRide?.bedsideNurse || {},
    appointmentTimeTransportReason: {
      startAppointmentDay: moment(
        defaultRide?.appointmentTime || moment().add(2, 'hours'),
      ),
      startAppointmentTime: moment(
        defaultRide?.appointmentTime || moment().add(2, 'hours'),
      ).format(HOUR_FORMAT_24),
    },
  };

  const {
    handleSubmit,
    errors,
    control,
    watch,
    register,
    setValue,
    getValues,
    formState,
    trigger,
    setError,
  } = useForm<typeof defaultValues>({
    defaultValues,
  });

  useEffect(() => {
    if (rideErrorsState) {
      rideErrorsState.forEach(rError => {
        setError(`ride.${rError.field}`, {
          message: rError.messages.join('\n'),
          type: 'custom',
        });
      });
    }
  }, [rideErrorsState, setError]);

  useEffect(() => {
    if (defaultRide?.startLocation && defaultRide?.endLocation) {
      const endLocationInstructions =
        // @ts-ignore
        defaultRide.endLocationInstructions ||
        defaultRide.endLocation.instructions;
      const startLocationInstructions =
        // @ts-ignore
        defaultRide.startLocationInstructions ||
        defaultRide.startLocation.instructions;

      setShowEndInstructions(!!endLocationInstructions);
      setShowStartInstructions(!!startLocationInstructions);

      setValue('ride.endLocationInstructions', endLocationInstructions);
      setValue('ride.startLocationInstructions', startLocationInstructions);
      setValue('ride.startLocation', defaultStartLocation(defaultRide));
      setValue('ride.endLocation', defaultEndLocation(defaultRide));
    }
  }, [
    setValue,
    defaultRide,
    defaultRide?.startLocation,
    defaultRide?.endLocation,
  ]);

  useEffect(() => {
    setValue('ride.pickupRoomNumber', defaultRide?.pickupRoomNumber ?? null);
    setValue('ride.dropoffRoomNumber', defaultRide?.dropoffRoomNumber ?? null);
  }, [setValue, defaultRide?.dropoffRoomNumber, defaultRide?.pickupRoomNumber]);

  const watchRide = watch('ride');
  const watchStartTime = watch('startTime');
  const watchEndTime = watch('endTime');
  const watchTransportType = watchRide.transportType
    ? watchRide.transportType
    : defaultValues.ride.transportType;
  const isRideshare = watchTransportType === 'rideshare';
  const isFlexibleRide = watchRide.flexibleRide;
  const isFirstAvailable = watchRide.firstAvailable;
  const firstAvailableBookingEnabled = account?.firstAvailableBookingEnabled;

  const [showMedicalNecessity, setShowMedicalNecessity] = useState(false);

  useEffect(() => {
    if (!isEmpty(formState?.dirtyFields)) {
      const weightLbs = useMetric // save always as LBS
        ? getLBSFromKg(watchRide.weight)
        : watchRide.weight;

      let transportationCopy = {
        ...watchRide,
        weightLbs,
        startTime: '',
        endTime: '',
      };

      // TypeScript is complaining that we are assigning a `string` to a `Moment`
      // object. This is fine because we want to store the string version to
      // storage. When we restore it, we convert it back to a `Moment` object.
      // @ts-ignore
      transportationCopy.requestedStartTime = watchRide.requestedStartTime?.toISOString();
      transportationCopy.startTime = watchStartTime ?? '';
      transportationCopy.endTime = watchEndTime ?? '';

      writeFormData(transportationCopy);
    }
  }, [watchRide, watchStartTime, watchEndTime, formState, useMetric]);

  useEffect(() => {
    if (watchRide.transportType === 'rideshare') {
      setValue('ride.rideType', 'oneway');
    }

    if (!!watchTransportType.match('ambulance')) {
      const selectedPayerType = profile?.availablePayerTypes?.find(
        payerType => payerType.promptForPayerDemographics === false,
      );

      if (selectedPayerType) {
        setValue('ride.payerType', selectedPayerType.name);
      }
    }
  }, [watchRide.transportType, setValue, profile?.availablePayerTypes]);

  useEffect(() => {
    // Set the default end time to be 1 hour after the start time when roundtrip is selected.
    // Don't do this for updates because we need it to be set to the end time the user selected.
    if (watchRide.rideType === 'roundtrip' && !isUpdate) {
      const endTime = momentFromTimeString(watchStartTime).add(1, 'hours');
      control.setValue('endTime', endTime.format(HOUR_FORMAT_24));
    }
  }, [watchRide.rideType, isUpdate]);

  const filteredRideTypes = useMemo(() => {
    if (watchRide.transportType === 'rideshare') {
      return [{ type: 'oneway', text: 'One Way' }];
    }

    return rideTypes;
  }, [watchRide.transportType]);

  const watchrecurringOptions = watch('recurringOptions');
  const watchmncsOptions = watch('mncsOptions');

  // requested start and end are calculated based on the date and time fields
  useEffect(() => {
    if (watchRide.requestedStartTime && watchStartTime) {
      setRequestedStartTime(
        momentFromTimeString(watchStartTime, watchRide.requestedStartTime),
      );
    }
  }, [watchRide.requestedStartTime, watchStartTime]);

  useEffect(() => {
    if (watchRide.requestedStartTime && watchEndTime && watchRide.rideType) {
      if (watchRide.rideType === 'roundtrip') {
        setRequestedEndTime(
          momentFromTimeString(watchEndTime, watchRide.requestedStartTime),
        );
      } else {
        setRequestedEndTime(null);
      }
    }
  }, [watchRide.requestedStartTime, watchEndTime, watchRide.rideType]);

  useEffect(() => {
    if (
      watchRide.heightInch !== undefined ||
      watchRide.heightInch !== null ||
      watchRide.heightFeet !== undefined ||
      watchRide.heightFeet !== null
    ) {
      // @ts-ignore
      if (watchRide.heightFeet === '' && watchRide.heightInch === '') {
        return;
      }

      const heightInches =
        (watchRide?.heightFeet || 0) * 12 + (watchRide.heightInch || 0);
      setValue('ride.heightInches', heightInches);
    }
  }, [setValue, watchRide.heightInch, watchRide.heightFeet]);

  const [
    getPickupSuggestedLocations,
    { data: dataPickupSuggestedLocations },
  ] = useRideSuggestedLocationsQueryLazyQuery();

  const [
    getEndSuggestedLocations,
    { data: dataEndSuggestedLocations },
  ] = useRideSuggestedLocationsQueryLazyQuery();

  const [getRiders, { data: ridersData }] = useRidersQueryLazyQuery();
  const [
    getRideNotesPlaceholder,
    { data: rideNotesPlaceholderData },
  ] = useRideNotesPlaceholderTextQueryLazyQuery();

  const { data: transportTypeOptionsData } = useTransportTypeOptions(
    watchTransportType,
  );

  useEffect(() => {
    setRidersList(prevRiderList => {
      if (ridersData) {
        const prevRidersIds = prevRiderList?.map(prevRider => prevRider.id);
        const newRidersList = ridersData.riders?.filter(
          fetchedRider => !prevRidersIds?.includes(fetchedRider.id),
        );

        if (newRidersList && prevRiderList) {
          return [...prevRiderList, ...newRidersList];
        }
      }

      return prevRiderList;
    });
  }, [ridersData, setRidersList]);

  useEffect(() => {
    getRiders({ variables: { query: '' } });
    getRideNotesPlaceholder({
      variables: { transportType: watchTransportType },
    });
  }, [getRiders, getRideNotesPlaceholder, watchTransportType]);

  // if the rider or account changes update the recent locations and payment options
  useEffect(() => {
    getPickupSuggestedLocations({
      variables: {
        riderId: watchRide.riderId,
        transportType: watchTransportType,
      },
    });

    getEndSuggestedLocations({
      variables: {
        riderId: watchRide.riderId,
        transportType: watchTransportType,
      },
    });

    if (watchRide.riderId) {
      const rider = ridersList?.find(r => r.id === watchRide.riderId);

      let pmOptions = [
        ...(account?.paymentMethods || []),
        ...(rider?.paymentMethods || []),
      ].map(pm => ({ id: pm.id, name: pm.name }));
      if (account?.invoiced) {
        pmOptions.push({ id: 'invoice', name: 'Invoice this ride' });
      }

      setPaymentMethodOptions(pmOptions);
      const updatedDefaultPaymentMethodId = rider
        ? defaultRiderPaymentMethodId({
            rider,
            account,
          })
        : null;

      setValue('ride.paymentMethodId', updatedDefaultPaymentMethodId);
    }
  }, [
    watchRide.riderId,
    ridersList,
    account,
    getPickupSuggestedLocations,
    getEndSuggestedLocations,
    setValue,
    watchTransportType,
  ]);

  const resetMncsOptions = useCallback(() => {
    Object.keys(watchmncsOptions).forEach(option => {
      setValue(`mncsOptions.${option}`, undefined);
    });
  }, [setValue, watchmncsOptions]);

  useEffect(() => {
    if (!watchTransportType.match('ambulance')) {
      resetMncsOptions();
    }
  }, [resetMncsOptions, watchTransportType]);

  const handleGoBack = useCallback(() => {
    setShowMedicalNecessity(false);
  }, []);

  const handlePayerDemographics = () => {
    const promptForPayerDemographicsValue = accountFinancialResponsibilityChoices?.find(
      (value: FinancialResponsibilityChoice) =>
        value.costCenter === watchRide.costCenter,
    )?.promptForPayerDemographics;

    setPromptForPayerDemographics(!!promptForPayerDemographicsValue);
  };

  const [
    getAccountFinancialResponsibility,
    { data: accountFinancialResponsibilityData },
  ] = useLazyQuery<any, QueryAccountFinancialResponsibilityChoicesArgs>(
    ACCOUNT_FINANCIAL_RESPONSIBILITY_CHOICE_QUERY,
    {
      onCompleted: ({ accountFinancialResponsibilityChoices }) => {
        handlePayerDemographics();

        if (accountFinancialResponsibilityChoices?.length === 1) {
          setValue(
            'ride.costCenter',
            accountFinancialResponsibilityChoices[0].costCenter,
          );
          setSingleCostCenterStyle('none');
        } else if (isEmpty(accountFinancialResponsibilityChoices)) {
          setValue('ride.costCenter', null);
          setValue('ride.payerType', null);
        } else {
          setSingleCostCenterStyle('inline-flex');
        }
      },
    },
  );

  useEffect(() => {
    if (watchRide.payerType) {
      getAccountFinancialResponsibility({
        variables: { accountId: account?.id, payerType: watchRide.payerType },
      });
    }
  }, [account?.id, watchRide.payerType]);

  useEffect(() => {
    const heightInches = watchRide.heightCm * INCHES_IN_CM;
    const heightInch = Math.floor(heightInches % 12);
    const heightFeet = Math.trunc(heightInches / 12);

    setValue('ride.heightInch', heightInch);
    setValue('ride.heightFeet', heightFeet);
  }, [watchRide.heightCm, setValue]);

  const submit = async (data: RideFormTypeSubmit) => {
    const { mncsOptions: mncsOptionsData, ...rest } = data;
    const mncsOptions: Array<CreateMncsInput> = mncsOptionsData
      ? Object.keys(mncsOptionsData)
          .filter(key => !!mncsOptionsData[key])
          .map(key => {
            return {
              slug: key,
              value: mncsOptionsData[key].toString(),
            };
          })
      : [];

    // TODO REFACTOR: Instead of using useState and adding it here, use it inside form fields
    const restData = { ...rest };
    restData.ride.arrivalBufferMinutes = bufferTime;
    restData.ride.startLocationPhoto = includeStartLocationPhoto
      ? restData.ride.startLocation?.locationPhoto || null
      : null;
    restData.ride.endLocationPhoto = includeEndLocationPhoto
      ? restData.ride.endLocation?.locationPhoto || null
      : null;

    formValuesRef.current = { ...restData, mncsOptions };

    if (shouldShowFulfillmentNoticeRef.current) {
      setShouldShowFulfillmentNotice(true);
      return;
    }

    await onSubmit({ ...restData, mncsOptions });

    // Reset the location photo fields.
    setIncludeStartLocationPhoto(true);
    setIncludeEndLocationPhoto(true);
  };

  const shouldShowFulfillmentNoticeRef = useRef(false);
  const formValuesRef = useRef<RideFormType | null>(null);

  const handleDOWChange = (checked: boolean, index: number) => {
    const {
      recurringOptions: { dow },
    } = getValues();
    if (!dow) {
      return [index];
    }
    const indexValue = dow.findIndex(val => val === index);
    if (indexValue !== -1) {
      dow[indexValue] = checked ? index : -1;
      const newSelectedDow = dow.filter(value => value !== -1).sort();
      return [...newSelectedDow];
    } else {
      const selectedValue = [...dow, index];
      return [...selectedValue].sort();
    }
  };

  const notesPlaceholder = useMemo(() => {
    return rideNotesPlaceholderData?.rideNotesPlaceholderText || undefined;
  }, [rideNotesPlaceholderData]);

  const accountFinancialResponsibilityChoices =
    accountFinancialResponsibilityData?.accountFinancialResponsibilityChoices;

  useEffect(() => {
    if (watchRide.costCenter) {
      handlePayerDemographics();
    }
  }, [watchRide.costCenter]);

  useEffect(() => {
    const riderInList = ridersList?.find(r => r.id === watchRide.riderId);
    if (watchRide.riderId && riderInList === undefined) {
      getRider({ variables: { riderId: watchRide.riderId } });
    }
  }, [watchRide.riderId, getRider, ridersList]);

  // TODO: this is a code duplication, but in this context there is aditional code that has some logic
  // that I could not find the use case, leaving this as it is for now to not break anything
  useEffect(() => {
    const rider = ridersList?.find(r => r.id === riderId);

    if (rider && riderId) {
      if (promptForPayerDemographics) {
        setValue('ride.patient.firstName', rider?.firstName);
        setValue('ride.patient.lastName', rider?.lastName);
        setValue('ride.patient.phone', rider?.phone);
        setValue('ride.patient.email', rider?.email);
      }

      const transportationForm = readSavedFormData();

      if (transportationForm) {
        transportationForm?.startTime &&
          setValue('startTime', transportationForm.startTime);
        transportationForm?.endTime &&
          setValue('endTime', transportationForm.endTime);
        Object.entries(transportationForm).forEach(([key, value]) => {
          if (value) {
            if (key === 'requestedStartTime') {
              setValue(`ride.${key}`, moment(value));
            } else if (key === 'riderId') {
              setValue(`ride.${key}`, riderId);
            } else {
              setValue(`ride.${key}`, value);
            }
          }
        });
      }
    }
  }, [
    riderId,
    profile,
    promptForPayerDemographics,
    setValue,
    ridersList,
    defaultRide,
  ]);

  useEffect(() => {
    if (showRecurringOptions) {
      setValue('ride.firstAvailable', false);
    }

    if (watchRide.firstAvailable) {
      setValue('ride.rideType', 'oneway');
    }
  }, [setValue, watchRide.firstAvailable, showRecurringOptions]);

  const errorPayerType = !!errors.ride?.payerType;
  const errorCostCenter = !!errors.ride?.costCenter;

  const debouncePickupSuggestedLocationSearch = debounce(
    getPickupSuggestedLocations,
    300,
  );
  const debounceEndSuggestedLocationSearch = debounce(
    getEndSuggestedLocations,
    300,
  );

  const getPickupLocationSearch = useCallback(
    (query?: string) => {
      if (query) {
        debouncePickupSuggestedLocationSearch({
          variables: {
            riderId: watchRide.riderId,
            transportType: watchTransportType,
            query,
          },
        });
      } else if (query === '') {
        debouncePickupSuggestedLocationSearch({
          variables: {
            riderId: watchRide.riderId,
            transportType: watchTransportType,
            query: '',
          },
        });
      }
    },
    [debouncePickupSuggestedLocationSearch, watchRide, watchTransportType],
  );

  const getEndLocationSearch = useCallback(
    (query?: string) => {
      if (query) {
        debounceEndSuggestedLocationSearch({
          variables: {
            riderId: watchRide.riderId,
            transportType: watchTransportType,
            query,
          },
        });
      } else if (query === '') {
        debounceEndSuggestedLocationSearch({
          variables: {
            riderId: watchRide.riderId,
            transportType: watchTransportType,
            query: '',
          },
        });
      }
    },
    [debounceEndSuggestedLocationSearch, watchRide.riderId, watchTransportType],
  );

  const getRidersOnChange = useCallback(
    (_, newValue: any) => {
      getRiders({ variables: { query: newValue } });
    },
    [getRiders],
  );

  const handleRiderChange = useCallback(
    onChange => (event: any, newValue: any) => {
      onChange(newValue?.id);
    },
    [],
  );

  useEffect(() => {
    const urlParams = new URLSearchParams(search);

    if (urlParams.has('riderID')) {
      // We need to remove the `riderID` query param so that they are able to
      // select another rider from the dropdown. Otherwise, it will keep
      // selecting the rider that matches the `riderID` query param.
      urlParams.delete('riderID');
      history.replace({
        search: urlParams.toString(),
      });
    }
  }, [history, search]);

  const selectedRider = useMemo(() => {
    return ridersList?.find(r => r.id === watchRide.riderId);
  }, [ridersList, watchRide]);

  useEffect(() => {
    if (selectedRider) {
      if (!watchRide.weight) {
        setValue(
          'ride.weight',
          useMetric
            ? getKgFromLBS(selectedRider.weightLbs || 0)
            : selectedRider.weightLbs,
        );
      }

      if (!watchRide.heightInches) {
        const inches = selectedRider.heightInches || 0;

        setValue('ride.heightInches', inches);

        const heightInch = Math.floor(inches % 12);
        const heightFeet = Math.trunc(inches / 12);

        setValue('ride.heightInch', heightInch);
        setValue('ride.heightFeet', heightFeet);

        setValue(
          'ride.heightCm',
          useMetric ? getCmFromInches(inches) : watchRide.heightCm,
        );
      }
    }
  }, [selectedRider, useMetric, watchTransportType]);

  const paymentMethodErrorMessage = () => {
    if (errors?.ride?.paymentMethodId) {
      // The `message` prop for this error is an empty string when the `type` is required.
      return errors.ride.paymentMethodId.type === 'required'
        ? 'Payment Method is required'
        : errors.ride.paymentMethodId.message;
    }

    return '';
  };

  shouldShowFulfillmentNoticeRef.current =
    !isRideshare &&
    Math.abs(
      moment.duration(moment().diff(watchRide.requestedStartTime)).asMinutes(),
    ) < REQUESTED_START_GAP_MINUTES;

  const disableSubmit = useMemo(() => {
    return (
      isRideshare &&
      selectedRider &&
      !validPhone(selectedRider?.phone)
    );
  }, [selectedRider, watchTransportType]);

  const transportTypeOptions = useMemo(() => {
    return {
      contactPrecautionsRequired: !!transportTypeOptionsData
        ?.rideTransportTypeOptions.contactPrecautionsRequired,
      oxygenRequired: !!transportTypeOptionsData?.rideTransportTypeOptions
        .oxygenRequired,
    };
  }, [actionType, transportTypeOptionsData, defaultRide]);

  const mncsOptionsErrorPresent = useMemo(() => {
    let mncsOptionsErrorsExist = false;
    Object.keys(errors).forEach(key => {
      if (key.includes('mncsOptions')) {
        mncsOptionsErrorsExist = true;
      }
    });
    return mncsOptionsErrorsExist;
  }, [Object.keys(errors).length]);

  const MapElement = useMemo(() => {
    if (isVetride) {
      return null;
    }
    return (
      <Grid item key="map" xs={false} sm={5} md={6} lg={8}>
        <Map
          // @ts-ignore
          center={{
            latitude: profile?.lastGeoLatitude || DEFAULT_LATITUDE,
            longitude: profile?.lastGeoLongitude || DEFAULT_LONGITUDE,
          }}
          startLocation={watchRide.startLocation}
          endLocation={watchRide.endLocation}
          startTime={!watchRide.flexibleRide && requestedStartTime}
          endTime={requestedEndTime}
          tzName={defaultRide?.tzName}
        />
      </Grid>
    );
  }, [
    isVetride,
    profile,
    watchRide,
    requestedStartTime,
    requestedEndTime,
    defaultRide,
  ]);

  const TransportReasonCategoriesComponent = useMemo(() => {
    return watchRide.transportReason || isCreate ? (
      <TransportReasonCategories
        control={control}
        transportReasonErrors={errors.ride?.transportReason}
        transportReason={watchRide.transportReason}
        transportReasonCommentErrors={errors.ride?.transportReasonComment}
        transportReasonDayErrors={
          errors.appointmentTimeTransportReason?.startAppointmentDay
        }
        transportReasonTimeErrors={
          errors.appointmentTimeTransportReason?.startAppointmentTime
        }
      />
    ) : null;
  }, [
    watchRide.transportReason,
    actionType,
    errors.ride?.transportReasonComment,
    errors.ride?.transportReason,
    errors.appointmentTimeTransportReason?.startAppointmentDay,
    errors.appointmentTimeTransportReason?.startAppointmentTime,
    control,
  ]);

  const MnscLink = () => (
    <>
      <InputLabel
        className={classes.formControl}
        id="medical-necessity-statement-label"
      >
        Medical Necessity Statement
      </InputLabel>

      <Button
        style={{ color: mncsOptionsErrorPresent ? 'red' : '' }}
        color={'primary'}
        variant={mncsOptionsErrorPresent ? 'outlined' : 'text'}
        onClick={() => setShowMedicalNecessity(true)}
        className={classes.addInstructionText}
      >
        {isUpdate
          ? 'Medical Necessity Statement'
          : 'Add Medical Necessity Statement'}
      </Button>
    </>
  );

  const riderInputRef = useRef<HTMLInputElement>(null);

  const RideErrors = useMemo(() => {
    console.log('validation errors:', errors);

    const riderErrorTypeIsCustom = errors.ride?.riderId?.type === 'custom';
    const riderHasNoPhoneForRideshare =
      isRideshare &&
      selectedRider &&
      !validPhone(selectedRider?.phone);
    const displayEditRiderLink =
      riderErrorTypeIsCustom || riderHasNoPhoneForRideshare;
    const riderErrorMessage = riderErrorTypeIsCustom
      ? errors.ride?.riderId?.message
      : 'Rider must have a valid SMS-Enabled Mobile Phone Number when an Uber / Lyft transport type is selected.';
    if (displayEditRiderLink) {
      riderInputRef?.current?.focus();
      return <FormHelperText error>{riderErrorMessage}</FormHelperText>;
    }
    if (errors.ride?.riderId) {
      return (
        <FormHelperText error>{`Select a ${
          watchRide.rideType === 'delivery' ? 'customer' : 'rider'
        }`}</FormHelperText>
      );
    }

    return null;
  }, [selectedRider, errors.ride, watchTransportType, watchRide.rideType]);

  const urlFromParams = generateFromParams();

  const RiderLink = useMemo(() => {
    return (
      <div style={{ display: 'flex' }}>
        {isEhrPatientContext === false && (
          <div style={{ marginRight: '1em' }}>
            <Link
              component={RouterLink}
              to={`/riders/select?${urlFromParams}`}
              variant="body2"
            >
              Add rider
            </Link>
          </div>
        )}
        {selectedRider ? (
          <Link
            component={RouterLink}
            to={`/riders/${selectedRider.id}?${urlFromParams}`}
            variant="body2"
          >
            Edit Rider
          </Link>
        ) : null}
      </div>
    );
  }, [isEhrPatientContext, selectedRider, urlFromParams]);

  const setUseMetricCb = (isMetric: boolean) => {
    setUseMetric(isMetric);

    setValue(
      'ride.weight',
      isMetric
        ? getKgFromLBS(watchRide.weight)
        : getLBSFromKg(watchRide.weight),
    );

    setValue(
      'ride.heightCm',
      isMetric ? getCmFromInches(watchRide.heightInches) : watchRide.heightCm,
    );
  };

  const isReturnTripAvailable = !isVetride && !isReturnTrip;
  const isRecurringTripAvailable = !isVetride && isCreate;

  const handlePickupTimeSubmit = (
    arrivalBufferMinutes: number,
    time: string,
    date: Moment | null,
  ) => {
    if (time && date) {
      const newTime = momentFromTimeString(time, date);
      setValue('ride.requestedStartTime', newTime);
      setRequestedStartTime(newTime);
      control.setValue('startTime', newTime.format(HOUR_FORMAT_24));
      setBufferTime(arrivalBufferMinutes);
    }
  };

  useEffect(() => {
    if (defaultRide?.arrivalBufferMinutes !== null) {
      setBufferTime(defaultRide?.arrivalBufferMinutes);
    }
  }, [defaultRide?.arrivalBufferMinutes]);

  return (
    <Box
      display="flex"
      flexDirection="column"
      justifyContent="center"
      flexGrow={1}
    >
      <AddMedicalNecessityStatement
        control={control}
        onGoBack={handleGoBack}
        mncsOptions={watchmncsOptions}
        errors={errors}
        trigger={trigger}
        watchTransportType={watchTransportType}
        showMedicalNecessity={showMedicalNecessity}
      />
      <Grid
        style={{
          display: showMedicalNecessity ? 'none' : 'flex',
          justifyContent: isVetride ? 'center' : 'normal',
        }}
        container
        wrap="nowrap"
        className={classes.container}
      >
        <Grid
          item
          xs={12}
          sm={isVetride ? 12 : 7}
          md={isVetride ? 12 : 6}
          lg={isVetride ? 12 : 4}
          key="createForm"
          className={classes.leftGrid}
        >
          <Paper className={classes.left}>
            {showSessionInfo && <SessionInformation />}

            {isVetride === false && (
              <Button
                color="primary"
                startIcon={<ArrowBackIcon />}
                onClick={() => history.goBack()}
              >
                Back
              </Button>
            )}

            <Typography component="h1" variant="h5">
              {isUpdate
                ? 'Update Transportation'
                : 'Book Transportation'}
            </Typography>

            {createError && <Alert severity="error">{createError} </Alert>}

            <form
              className={classes.form}
              noValidate
              onSubmit={handleSubmit(submit)}
            >
              <FormControl className={classes.formControl}>
                <Controller
                  control={control}
                  name="ride.riderId"
                  rules={{ required: true }}
                  onChange={([, data]: any) => data}
                  render={({ onChange, ...props }) => {
                    return (
                      <Autocomplete
                        disabled={
                          isUpdate || isEhrPatientContext
                        }
                        getOptionLabel={option => {
                          // true means that the user selected the rider from the list.
                          if (typeof option === 'object') {
                            return `${option?.firstName} ${option?.lastName}`;
                          }

                          const riderInList = ridersList?.find(
                            r => r.id === option,
                          );

                          const { rider } = defaultRide || {};

                          if (riderInList) {
                            const { firstName, lastName } = riderInList;
                            return `${firstName} ${lastName}`;
                          } else if (rider) {
                            return `${rider.firstName} ${rider.lastName}`;
                          }

                          return '';
                        }}
                        data-testid="rider-autocomplete"
                        value={props.value}
                        options={ridersData?.riders?.map(rider => rider) || []}
                        getOptionSelected={(option, value) => {
                          return option.id === value;
                        }}
                        filterOptions={x => x} // don't filter
                        filterSelectedOptions
                        autoComplete
                        includeInputInList
                        onInputChange={getRidersOnChange}
                        onChange={handleRiderChange(onChange)}
                        renderOption={option => {
                          return (
                            <MenuItem value={option.id} key={option.id}>
                              <ListItemAvatar>
                                <PersonImage person={option} size="medium" />
                              </ListItemAvatar>
                              <ListItemText
                                id={option.id}
                                primary={personName(option)}
                                secondary={
                                  <div>
                                    {option.dob
                                      ? `DOB: ${fullNumericDate(option.dob)}`
                                      : ''}
                                    <div style={{ display: 'flex' }}>
                                      {option.paymentMethods.length ? (
                                        <ZeroStateText>{`${option.paymentMethods?.[0]?.cardBrand?.toUpperCase()} ** ${
                                          option.paymentMethods?.[0].cardLast4
                                        }`}</ZeroStateText>
                                      ) : null}
                                    </div>
                                  </div>
                                }
                              />
                            </MenuItem>
                          );
                        }}
                        renderInput={params => {
                          return (
                            <TextField
                              {...params}
                              inputRef={riderInputRef}
                              data-testid="rider-autocomplete-text"
                              label={'Rider'}
                              error={!!errors.ride?.riderId}
                              name={'Rider'}
                              helperText={RideErrors}
                            />
                          );
                        }}
                      />
                    );
                  }}
                />
              </FormControl>

              {isCreate && (
                <FormControl className={classes.formControl}>
                  {RiderLink}
                </FormControl>
              )}

              <TransportTypeSelector
                control={control}
                transportTypeErrors={errors.ride?.transportType}
                watchRide={watchRide}
                transportTypes={availableTransportTypes}
                disabled={isUpdate}
              />
              <TransportTypeOptions
                actionType={actionType}
                control={control}
                errorWeight={errors.ride?.weight}
                errorHeightInches={errors.ride?.heightInches}
                transportType={watchTransportType}
                watchRide={watchRide}
                useMetric={useMetric}
                setUseMetric={setUseMetricCb}
                stairsCount={watchRide.stairsCount}
              />

              {TransportReasonCategoriesComponent}

              {/*<TransportReasonCategories*/}
              {/*  actionType={actionType}*/}
              {/*  control={control}*/}
              {/*  transportReasonErrors={errors.ride?.transportReason}*/}
              {/*  transportReason={watchRide.transportReason}*/}
              {/*  transportReasonComment={watchRide.transportReasonComment}*/}
              {/*  transportReasonCommentErrors={*/}
              {/*    errors.ride?.transportReasonComment*/}
              {/*  }*/}
              {/*  transportReasonDayErrors={*/}
              {/*    errors.appointmentTimeTransportReason?.startAppointmentDay*/}
              {/*  }*/}
              {/*  transportReasonTimeErrors={*/}
              {/*    errors.appointmentTimeTransportReason?.startAppointmentTime*/}
              {/*  }*/}
              {/*/>*/}
              {transportTypeOptionsData?.rideTransportTypeOptions
                .promptForBedsideNurseContact && (
                <BedSideNurseComponent
                  control={control}
                  disabled={false}
                  errors={errors}
                />
              )}
              <TransportTypeFeeOptionsComponent
                control={control}
                disabled={false}
                options={transportTypeOptions}
              />
              {!isUpdate &&
                profile?.promptForFinancialResponsibility &&
                profile?.availablePayerTypes && (
                  <>
                    <FinancialResponsibilityForm
                      control={control}
                      account={account}
                      errors={errors}
                      profile={profile}
                      getAccountFinancialResponsibility={
                        getAccountFinancialResponsibility
                      }
                      watchTransportType={watchTransportType}
                      accountFinancialResponsibilityChoices={
                        accountFinancialResponsibilityChoices
                      }
                      errorPayerType={errorPayerType}
                      errorCostCenter={errorCostCenter}
                      singleCostCenterStyle={singleCostCenterStyle}
                      setValue={setValue}
                      promptForPayerDemographics={promptForPayerDemographics}
                      ridersList={ridersList}
                      defaultRider={defaultRider}
                      defaultRide={defaultRide}
                      watchRide={watchRide}
                      riderID={riderId ?? null}
                    />
                  </>
                )}
              {transportTypeOptionsData?.rideTransportTypeOptions
                .promptForMncs && <MnscLink />}
              <FormControl
                className={classes.formControl}
                error={!!errors.ride?.rideType}
              >
                <InputLabel id="select-type-label">Ride Type</InputLabel>

                <Controller
                  control={control}
                  name="ride.rideType"
                  rules={{ required: true }}
                  as={
                    <Select labelId="select-type-label" id="rideTypeSelect">
                      {filteredRideTypes.map(rideType => (
                        <MenuItem
                          disabled={
                            watchRide.firstAvailable &&
                            rideType.type !== 'oneway'
                          }
                          key={rideType.type}
                          value={rideType.type}
                        >
                          {rideType.text}
                        </MenuItem>
                      ))}
                    </Select>
                  }
                />
                {errors.ride?.rideType && (
                  <FormHelperText>Select a ride type</FormHelperText>
                )}
              </FormControl>
              {isRideshare && (
                <RideshareComponents
                  control={control}
                  isUpdate={isUpdate}
                  showRecurringOptions={showRecurringOptions}
                  firstAvailableEnabled={firstAvailableBookingEnabled}
                  isFlexibleRide={isFlexibleRide}
                  isFirstAvailable={isFirstAvailable}
                />
              )
            }

              <Controller
                control={control}
                name="ride.startLocation"
                rules={{ required: true, validate: trueAddress }}
                onChange={([, data]: any) => data}
                render={({ onChange, ...props }) => {
                  return (
                    <GooglePlacesAutocomplete
                      center={{
                        latitude:
                          profile?.lastGeoLatitude?.toString() ||
                          DEFAULT_LATITUDE.toString(),
                        longitude:
                          profile?.lastGeoLongitude?.toString() ||
                          DEFAULT_LONGITUDE.toString(),
                      }}
                      id="startLocation"
                      value={props.value}
                      inputStyle={classes.formControl}
                      suggestedLocations={
                        dataPickupSuggestedLocations?.rideSuggestedLocations ||
                        []
                      }
                      label="Pickup Location"
                      inputError={!!errors.ride?.startLocation}
                      onChange={onChange}
                      onInputChange={(value: string) => {
                        getPickupLocationSearch(value);
                      }}
                      helperText={
                        errors.ride?.startLocation &&
                        ((errors.ride?.startLocation.type === 'required' &&
                          'Pickup location is required') ||
                          (errors.ride?.startLocation.type === 'validate' &&
                            'Pickup location is invalid'))
                      }
                    />
                  );
                }}
              />
              {watchRide.startLocation && watchRide.rideType !== 'delivery' && (
                <>
                  {!watchRide.startLocation.instructions &&
                    !showStartInstructions && (
                      <Box
                        alignSelf="flex-end"
                        display="flex"
                        alignItems="center"
                        className={classes.instructionLink}
                      >
                        <Button
                          color="primary"
                          onClick={() => setShowStartInstructions(true)}
                          className={classes.addInstructionText}
                        >
                          + Add Location Instructions
                        </Button>
                        <Tooltip title="What should your drivers know about this location? Should they come inside? Parking? Would you like a text or call upon arrival?">
                          <InfoIcon fontSize="small" color="primary" />
                        </Tooltip>
                      </Box>
                    )}
                  {(watchRide.startLocation.instructions ||
                    showStartInstructions) && (
                    <TextField
                      id="startLocationInstructions"
                      className={classes.formControl}
                      name="ride.startLocationInstructions"
                      defaultValue={watchRide.startLocation.instructions}
                      label={
                        <LabelWithTooltip
                          labelText="Pickup Location Instructions"
                          tooltipText="What should your drivers know about this location? Should they come inside? Parking? Would you like a text or call upon arrival?"
                        />
                      }
                      multiline
                      maxRows={3}
                      inputRef={register()}
                    />
                  )}
                  {watchRide.startLocation &&
                    watchRide.startLocation.locationPhoto && (
                      <div className={classes.locationPhoto}>
                        <LocationPhoto
                          locationPhoto={watchRide.startLocation.locationPhoto}
                          isDeleted={includeStartLocationPhoto === false}
                          onDeletePhoto={() =>
                            setIncludeStartLocationPhoto(false)
                          }
                        />
                      </div>
                    )}
                </>
              )}
              {!isRideshare && profile?.promptForRoomNumbers && (
                  <Grid container className={classes.formControl}>
                    <Grid item xs={12}>
                      <Controller
                        name="ride.pickupRoomNumber"
                        control={control}
                        render={props => {
                          return (
                            <TextField
                              value={props.value || ''}
                              fullWidth
                              label="Pickup Room Number"
                              onChange={e => {
                                if (e.target.value.length <= 10) {
                                  props.onChange(e.target.value);
                                }
                              }}
                            />
                          );
                        }}
                      />
                    </Grid>
                  </Grid>
                )}
              <Controller
                control={control}
                name="ride.endLocation"
                rules={{ required: true, validate: trueAddress }}
                render={({ onChange, ...props }) => {
                  return (
                    <GooglePlacesAutocomplete
                      center={{
                        latitude:
                          profile?.lastGeoLatitude?.toString() ||
                          DEFAULT_LATITUDE.toString(),
                        longitude:
                          profile?.lastGeoLongitude?.toString() ||
                          DEFAULT_LONGITUDE.toString(),
                      }}
                      value={props.value}
                      suggestedLocations={
                        dataEndSuggestedLocations?.rideSuggestedLocations || []
                      }
                      id="endLocation"
                      inputStyle={classes.formControl}
                      label="Dropoff Location"
                      inputError={!!errors.ride?.endLocation}
                      onChange={onChange}
                      onInputChange={(value: string) => {
                        getEndLocationSearch(value);
                      }}
                      helperText={
                        errors.ride?.endLocation &&
                        ((errors.ride?.endLocation.type === 'required' &&
                          'Dropoff location is required') ||
                          (errors.ride?.endLocation.type === 'validate' &&
                            'Dropoff location is invalid'))
                      }
                    />
                  );
                }}
              />
              {watchRide.endLocation && (
                <>
                  {!watchRide.endLocation.instructions && !showEndInstructions && (
                    <Box
                      alignSelf="flex-end"
                      display="flex"
                      alignItems="center"
                      className={classes.instructionLink}
                    >
                      <Button
                        color="primary"
                        onClick={() => setShowEndInstructions(true)}
                        className={classes.addInstructionText}
                      >
                        + Add Location Instructions
                      </Button>
                      <Tooltip
                        title={
                          watchRide.rideType === 'delivery'
                            ? 'The driver will drop your items at the door.  What should your driver know about this location? Parking? Would you like a text or call upon arrival? '
                            : 'What should your drivers know about this location? Should they come inside? Parking? Would you like a text or call upon arrival?'
                        }
                      >
                        <InfoIcon fontSize="small" color="primary" />
                      </Tooltip>
                    </Box>
                  )}
                  {(watchRide.endLocation.instructions ||
                    showEndInstructions) && (
                    <TextField
                      id="endLocationInstructions"
                      className={classes.formControl}
                      name="ride.endLocationInstructions"
                      label="Dropoff Location Instructions"
                      defaultValue={watchRide.endLocation.instructions}
                      multiline
                      maxRows={3}
                      inputRef={register()}
                    />
                  )}
                  {watchRide.endLocation &&
                    watchRide.endLocation.locationPhoto && (
                      <div className={classes.locationPhoto}>
                        <LocationPhoto
                          locationPhoto={watchRide.endLocation.locationPhoto}
                          isDeleted={includeEndLocationPhoto === false}
                          onDeletePhoto={() =>
                            setIncludeEndLocationPhoto(false)
                          }
                        />
                      </div>
                    )}
                </>
              )}
              {!isRideshare &&
                profile?.promptForRoomNumbers && (
                  <Grid container className={classes.formControl}>
                    <Grid item xs={12}>
                      <Controller
                        name="ride.dropoffRoomNumber"
                        control={control}
                        render={props => {
                          return (
                            <TextField
                              value={props.value || ''}
                              fullWidth
                              label="Dropoff Room Number"
                              onChange={e => {
                                if (e.target.value.length <= 10) {
                                  props.onChange(e.target.value);
                                }
                              }}
                            />
                          );
                        }}
                      />
                    </Grid>
                  </Grid>
                )}
              {!watchRide.firstAvailable && (
                <Grid container className={classes.formTimeControl}>
                  <Grid item xs={7} sm={5}>
                    <Controller
                      className={classes.startDay}
                      control={control}
                      name="ride.requestedStartTime"
                      rules={{ required: true }}
                      render={({ ref, ...rest }) => (
                        <DatePicker
                          id="requestedStartTime"
                          autoOk
                          label="Pickup Day"
                          disablePast
                          error={!!errors.ride?.requestedStartTime}
                          helperText={
                            errors.ride?.requestedStartTime &&
                            'Pickup day is required'
                          }
                          {...rest}
                        />
                      )}
                    />
                  </Grid>
                  <Grid item xs={5} sm={4}>
                    {!watchRide?.flexibleRide && (
                      <Controller
                        control={control}
                        name="startTime"
                        rules={{ required: true }}
                        render={({ ref, ...rest }) => (
                          <TextField
                            id="startTime"
                            label="Pickup Time"
                            type="time"
                            style={{ width: '100%' }}
                            error={!!errors.startTime}
                            helperText={
                              errors.startTime && 'Pickup time is required'
                            }
                            InputLabelProps={{
                              shrink: true,
                            }}
                            inputProps={{
                              step: 300, // 5 min
                            }}
                            {...rest}
                          />
                        )}
                      />
                    )
                    }
                  </Grid>
                  <Grid item xs={12} sm={3}>
                    <RideTimeHelper
                      defaultDate={watchRide.requestedStartTime}
                      defaultTime={watchStartTime}
                      disabled={
                        !watchRide.startLocation ||
                        !watchRide.endLocation ||
                        !requestedStartTime
                      }
                      ride={control.getValues().ride}
                      handleSubmit={(buffer, time, date) =>
                        handlePickupTimeSubmit(buffer, time, date)
                      }
                      bufferMinutes={bufferTime}
                      isFlexibleRide={watchRide.flexibleRide}
                    />
                  </Grid>
                </Grid>
              )}
              {watchRide.rideType === 'roundtrip' && (
                <Grid container className={classes.formControl}>
                  <Grid item xs={7} sm={5}></Grid>
                  <Grid item xs={5} sm={4}>
                    <Controller
                      control={control}
                      name="endTime"
                      rules={{
                        required: true,
                        validate: e => {
                          const endTime = momentFromTimeString(e);
                          const startTime = momentFromTimeString(
                            watchStartTime,
                          );
                          const duration = moment.duration(
                            endTime.diff(startTime),
                          );

                          return duration.asMinutes() > DROPOFF_START_TIME_DIFF;
                        },
                      }}
                      render={({ ref, ...rest }) => (
                        <TextField
                          id="endTime"
                          label="Dropoff Time"
                          type="time"
                          style={{ width: '100%' }}
                          error={!!errors.endTime}
                          helperText={
                            errors.endTime &&
                            ((errors.endTime.type === 'required' &&
                              'Dropoff time is required') ||
                              (errors.endTime.type === 'validate' &&
                                `Dropoff time must be after pickup time at least ${DROPOFF_START_TIME_DIFF} minutes`))
                          }
                          InputLabelProps={{
                            shrink: true,
                          }}
                          inputProps={{
                            step: 300, // 5 min
                          }}
                          {...rest}
                        />
                      )}
                    />
                  </Grid>
                  <Grid item xs={12} sm={3}></Grid>
                </Grid>
              )}
              {watchRide.rideType !== 'delivery' && (
                <FormControl className={classes.formControl}>
                  <InputLabel id="select-passenger-count-label">
                    Passenger Count
                  </InputLabel>

                  <Controller
                    control={control}
                    name="ride.passengerCount"
                    rules={{ required: true }}
                    as={
                      <Select
                        labelId="select-passenger-count-label"
                        id="passengerCountSelect"
                      >
                        <MenuItem key={1} value={1}>
                          1
                        </MenuItem>
                        <MenuItem key={2} value={2}>
                          2
                        </MenuItem>
                        <MenuItem key={3} value={3}>
                          3
                        </MenuItem>
                        <MenuItem key={4} value={4}>
                          4
                        </MenuItem>
                      </Select>
                    }
                  />
                </FormControl>
              )}
              <LabelWithTooltip
                labelText={
                  watchRide.rideType === 'delivery'
                    ? 'Delivery Details'
                    : 'Ride Notes'
                }
                tooltipText={
                  watchRide.rideType === 'delivery'
                    ? 'Please describe the items you would like and any other details about your delivery. You will be able to update this later.'
                    : `Please leave any additional notes about this ride for your driver. ${
                        notesPlaceholder || ''
                      }`
                }
              />
              <TextField
                id="notes"
                InputLabelProps={{
                  style: { pointerEvents: 'auto' },
                  shrink: notesPlaceholder ? true : undefined,
                }}
                className={classes.formControl}
                name="ride.notes"
                placeholder={notesPlaceholder}
                multiline
                minRows={4}
                inputRef={register()}
              />
              {watchRide.rideType === 'delivery' && (
                <FormControl
                  component="fieldset"
                  className={classes.formControl}
                >
                  <FormControlLabel
                    label={
                      <LabelWithTooltip
                        labelText="Allow Substitutions"
                        tooltipText="After unsuccessfully trying to contact you should your driver make substitutions?"
                      />
                    }
                    control={
                      <Controller
                        name="ride.deliverySubstitution"
                        control={control}
                        render={props => (
                          <Switch
                            onChange={e =>
                              props.onChange(e.target.checked ? 'yes' : 'no')
                            }
                            checked={!!props.value}
                          />
                        )}
                      />
                    }
                  />
                </FormControl>
              )}
              {paymentMethodOptions.length <= 1 &&
                !profile?.promptForFinancialResponsibility && (
                  <FormControl
                    error={!!errors.ride?.paymentMethodId}
                    className={classes.block}
                  >
                    <Controller
                      defaultValue={watchRide.paymentMethodId} //required to explicitly set default with watch
                      as={<input />}
                      rules={{ required: true }}
                      type="hidden"
                      name="ride.paymentMethodId"
                      control={control}
                    />

                    {errors.ride?.paymentMethodId && (
                      <FormHelperText>
                        A payment method is required
                      </FormHelperText>
                    )}
                  </FormControl>
                )}
              {!!watchRide.riderId &&
                watchRide.startLocation &&
                watchRide.endLocation &&
                requestedStartTime &&
                (watchRide.rideType !== 'roundtrip' || requestedEndTime) && (
                  <Box className={classes.fareSection}>
                    {paymentMethodOptions.length === 0 && (
                      <Box textAlign="center">
                        <Box
                          className={classes.addPaymentText}
                          fontStyle="italic"
                          fontWeight="fontWeightMedium"
                        >
                          To book a ride, you first need to add a payment method
                        </Box>

                        <span>
                          <Button
                            onClick={handleClick}
                            // className={classes.actionButton}
                            color="primary"
                            startIcon={<AddIcon />}
                          >
                            Add Payment Method
                          </Button>
                        </span>

                        {stripePromise && stripeOptions && (
                          <div className={classes.cardStyle}>
                            <Elements
                              stripe={stripePromise}
                              options={stripeOptions}
                            >
                              <StripeForm
                                shouldShowAddButton={true}
                                stripeData={stripeData}
                                getSetupIntent={getSetupIntent}
                                getAccount={getAccountData}
                                ownerType={'Account'}
                              />
                            </Elements>
                          </div>
                        )}
                      </Box>
                    )}
                    {paymentMethodOptions.length === 1 && (
                      <Box display="flex">
                        <CreditCardIcon
                          color="secondary"
                          className={classes.cardIcon}
                        />

                        <Box flexGrow={1}>{paymentMethodOptions[0].name} </Box>
                      </Box>
                    )}
                    {paymentMethodOptions.length > 1 && (
                      <FormControl
                        className={classes.formControl}
                        error={!!errors.ride?.paymentMethodId}
                      >
                        <InputLabel id="select-payment-method-label">
                          Payment Method
                        </InputLabel>

                        <Controller
                          control={control}
                          name="ride.paymentMethodId"
                          defaultValue={watchRide.paymentMethodId || ''} //required to explicitly set default with watch
                          rules={{ required: true }}
                          as={
                            <Select
                              labelId="select-payment-method-label"
                              id="paymentMethodSelect"
                            >
                              {paymentMethodOptions.map(pm => (
                                <MenuItem key={pm.id} value={pm.id}>
                                  {pm.name}
                                </MenuItem>
                              ))}
                            </Select>
                          }
                        />
                        {errors.ride?.paymentMethodId && (
                          <FormHelperText error>
                            {paymentMethodErrorMessage()}
                          </FormHelperText>
                        )}
                      </FormControl>
                    )}
                    <Box className={classes.estimate}>
                      <RideEstimate
                        isFlexibleRide={watchRide.flexibleRide}
                        rideType={watchRide.rideType}
                        startLocation={watchRide.startLocation}
                        endLocation={watchRide.endLocation}
                        requestedStartTime={requestedStartTime}
                        requestedEndTime={requestedEndTime}
                        transportType={watchTransportType}
                        weightLbs={
                          isMetric
                            ? getLBSFromKg(watchRide.weight)
                            : watchRide.weight
                        }
                        stairsCount={watchRide.stairsCount}
                        oxygenRequired={watchRide.oxygenRequired}
                        contactPrecautionsRequired={
                          watchRide.contactPrecautionsRequired
                        }
                        arrivalBufferMinutes={bufferTime}
                      />
                    </Box>
                  </Box>
                )}
              {admin && !isUpdate && (
                <FormControl
                  component="fieldset"
                  className={classes.formControl}
                >
                  <FormControlLabel
                    label={
                      <LabelWithTooltip
                        labelText="Draft"
                        tooltipText="(only admins see this)"
                      />
                    }
                    control={
                      <Controller
                        name="ride.draft"
                        control={control}
                        render={props => (
                          <Switch
                            onChange={e => props.onChange(e.target.checked)}
                            checked={!!props.value}
                          />
                        )}
                      />
                    }
                  />
                </FormControl>
              )}
              {isRecurringTripAvailable && (
                <FormControl
                  component="fieldset"
                  className={classes.formControl}
                  disabled={watchRide.firstAvailable}
                >
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={showRecurringOptions}
                        onChange={toggleRecurringOptions}
                      />
                    }
                    label={
                      <LabelWithTooltip
                        style={{ paddingTop: 3 }}
                        labelText={'Recurring Trip'}
                        tooltipText={
                          'Check this box to create a repeating trip based on a schedule'
                        }
                      />
                    }
                  />
                </FormControl>
              )}
              {showRecurringOptions && (
                <Box className={classes.fareSection}>
                  <Grid alignItems={'center'} container spacing={2}>
                    <Grid item xs={5}>
                      <Typography style={{ paddingTop: 15 }} variant="body1">
                        Repeat Every
                      </Typography>
                    </Grid>
                    <Grid item xs={2}>
                      <FormControl
                        component="fieldset"
                        className={classes.formControl}
                      >
                        <Controller
                          name="recurringOptions.interval"
                          control={control}
                          min={1}
                          max={28}
                          defaultValue={watchrecurringOptions.interval} //required to explicitly set default with watch
                          render={props => {
                            return (
                              <Input
                                value={props.value}
                                id="interval"
                                onChange={e => {
                                  if (e.target.value === '') {
                                    props.onChange(e.target.value);
                                  }

                                  if (
                                    !Number.isInteger(
                                      Number.parseInt(e.target.value),
                                    )
                                  ) {
                                    return;
                                  }
                                  if (
                                    +e.target.value < 1 ||
                                    +e.target.value > 28
                                  ) {
                                    return;
                                  } else {
                                    props.onChange(+e.target.value);
                                  }
                                }}
                                type="number"
                              />
                            );
                          }}
                          rules={{ required: true }}
                        />
                      </FormControl>
                    </Grid>
                    <Grid item xs={5} className={classes.withTooltip}>
                      <FormControl component="fieldset" style={{ flex: 1 }}>
                        <Controller
                          name="recurringOptions.repeat"
                          control={control}
                          defaultValue={watchrecurringOptions.repeat} //required to explicitly set default with watch
                          render={props => {
                            return (
                              <Select
                                value={props.value}
                                onChange={props.onChange}
                              >
                                <MenuItem value={'weekly'}>Week</MenuItem>
                                <MenuItem value={'daily'}>Day</MenuItem>
                              </Select>
                            );
                          }}
                        />
                      </FormControl>
                      <Tooltip
                        style={{ marginTop: 10, marginLeft: 5 }}
                        title={
                          'Set your recurrence schedule - either daily or weekly'
                        }
                      >
                        <InfoIcon fontSize="small" color="primary" />
                      </Tooltip>
                    </Grid>
                  </Grid>
                  {watchrecurringOptions.repeat === 'weekly' && (
                    <Grid
                      alignItems={'baseline'}
                      container
                      style={{ marginTop: 20 }}
                    >
                      <Grid item xs={12}>
                        <Typography
                          className={
                            errors.recurringOptions?.dow ? classes.root : ''
                          }
                          variant="body1"
                        >
                          Repeat On:
                        </Typography>
                      </Grid>
                      <Grid item xs={12}>
                        <FormGroup
                          row
                          style={{
                            flexWrap: 'nowrap',
                          }}
                        >
                          {['S', 'M', 'T', 'W', 'T', 'F', 'S'].map(
                            (day, index) => (
                              <FormControlLabel
                                style={{ margin: 0 }}
                                labelPlacement="bottom"
                                control={
                                  <FormControl
                                    component="fieldset"
                                    className={classes.formControl}
                                  >
                                    <Controller
                                      name="recurringOptions.dow"
                                      rules={{
                                        validate: e => {
                                          if (!e || e?.length === 0) {
                                            return 'Should be selected at least one day';
                                          }
                                          return true;
                                        },
                                      }}
                                      render={props => {
                                        return (
                                          <Checkbox
                                            className={
                                              errors.recurringOptions?.dow &&
                                              classes.root
                                            }
                                            style={{ padding: 0 }}
                                            defaultChecked={props.value?.includes(
                                              index,
                                            )}
                                            onChange={ev => {
                                              props.onChange(
                                                handleDOWChange(
                                                  ev.target.checked,
                                                  index,
                                                ),
                                              );
                                            }}
                                          />
                                        );
                                      }}
                                      control={control}
                                    />
                                  </FormControl>
                                }
                                key={index}
                                label={
                                  <span
                                    style={
                                      errors.recurringOptions?.dow && {
                                        color: onwardColors.onwardRed,
                                        fontWeight: 'bold',
                                      }
                                    }
                                  >
                                    {day}
                                  </span>
                                }
                              />
                            ),
                          )}
                        </FormGroup>
                        {errors.recurringOptions?.dow && (
                          <FormHelperText style={{ fontSize: 14 }} error>
                            {errors.recurringOptions?.dow.message}
                          </FormHelperText>
                        )}
                      </Grid>
                    </Grid>
                  )}

                  <Grid
                    style={{ marginTop: 10 }}
                    alignItems={'center'}
                    container
                    spacing={2}
                  >
                    <Grid item xs={5}>
                      <Typography style={{ paddingTop: 15 }} variant="body1">
                        Repeat Until:
                      </Typography>
                    </Grid>
                    <Grid item xs={7} className={classes.withTooltip}>
                      <FormControl component="fieldset" style={{ flex: 1 }}>
                        <Controller
                          className={classes.startDay}
                          control={control}
                          name="recurringOptions.untilDate"
                          rules={{
                            required: true,
                            validate: e => {
                              if (
                                e &&
                                requestedStartTime &&
                                e.isSameOrBefore(requestedStartTime, 'day')
                              ) {
                                return false;
                              }

                              return true;
                            },
                          }}
                          defaultValue={watchrecurringOptions.untilDate}
                          render={({ ref, ...rest }) => {
                            return (
                              <DatePicker
                                id="recurringStartDay"
                                autoOk
                                disablePast
                                minDateMessage="Date should be after Pickup Day"
                                minDate={moment(requestedStartTime).add(
                                  1,
                                  'days',
                                )}
                                maxDate={moment().add(6, 'M')}
                                {...rest}
                              />
                            );
                          }}
                        />
                      </FormControl>
                      <Tooltip
                        style={{ marginTop: 10, marginLeft: 5 }}
                        title={
                          'Set an end date for your schedule. You may schedule rides up to 6 months into the future'
                        }
                      >
                        <InfoIcon fontSize="small" color="primary" />
                      </Tooltip>
                    </Grid>
                  </Grid>
                  {admin && (
                    <Grid container>
                      <Grid item xs={12} className={classes.withTooltip}>
                        <FormControl component="fieldset" style={{ flex: 1 }}>
                          <Controller
                            name="recurringOptions.notes"
                            control={control}
                            render={props => {
                              return (
                                <TextField
                                  fullWidth
                                  multiline
                                  maxRows={4}
                                  label="Notes"
                                  onChange={props.onChange}
                                  variant="standard"
                                  value={props.value}
                                />
                              );
                            }}
                          />
                        </FormControl>
                        <Tooltip
                          style={{ marginTop: 20, marginLeft: 5 }}
                          title={
                            'Add a description to help us manage your trips'
                          }
                        >
                          <InfoIcon fontSize="small" color="primary" />
                        </Tooltip>
                      </Grid>
                    </Grid>
                  )}
                </Box>
              )}
              {isReturnTripAvailable && (
                <FormControl
                  component="fieldset"
                  className={classes.formControl}
                >
                  <FormControlLabel
                    control={
                      <Controller
                        name="isReturnTrip"
                        control={control}
                        render={props => {
                          return (
                            <Checkbox
                              onChange={e => {
                                props.onChange(e.target.checked);
                              }}
                              checked={!!props.value}
                            />
                          );
                        }}
                      />
                    }
                    label={
                      <LabelWithTooltip
                        style={{ paddingTop: 3 }}
                        labelText={'Book a Return Trip'}
                        tooltipText={
                          'Check this box if you would like to create a return trip.  After saving we will prefill this page to enable you to easily create a second trip or return trip'
                        }
                      />
                    }
                  />
                </FormControl>
              )}

              <div className={classes.formActions}>
                {Object.keys(errors).length > 0 && (
                  <Typography
                    className={classes.formActionsValidation}
                    variant="body2"
                    color="error"
                  >
                    There are validation errors, please fix and try again.
                  </Typography>
                )}

                <Button
                  key="submitButton"
                  type="submit"
                  variant="contained"
                  color="primary"
                  className={classes.submit}
                  disabled={isSubmitting || disableSubmit}
                >
                  {isUpdate
                    ? 'Update Transportation'
                    : 'Book Transportation'}
                </Button>
              </div>
            </form>

            {isEhrPatientContext && (
              <>
                <Divider />
                <HelpInformation />
              </>
            )}
          </Paper>
        </Grid>

        {MapElement}
      </Grid>
      <ConfirmNotifyDialog
        open={shouldShowFulfillmentNotice}
        handleConfirm={() =>
          formValuesRef.current && onSubmit(formValuesRef.current)
        }
        handleClose={() => setShouldShowFulfillmentNotice(false)}
      />
    </Box>
  );
};
