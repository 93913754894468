import React from 'react';
import { Controller } from 'react-hook-form';
import {
  FormControl,
  FormControlLabel,
  Checkbox,
} from '@material-ui/core';
import { LabelWithTooltip } from 'components/LabelWithTooltip';
import { CheckboxProps } from './types';
import useStyles from './styles';

const _FirstAvailableCheckboxComponent: React.FC<CheckboxProps> = ({
  control,
  disabled,
}) => {
  const classes = useStyles();

  return (
    <FormControl component="fieldset" className={classes.formControl}>
      <FormControlLabel
        control={
          <Controller
            name={`ride.firstAvailable`}
            control={control}
            render={props => {
              return (
                <Checkbox
                  disabled={disabled}
                  onChange={e => {
                    props.onChange(e.target.checked);
                  }}
                  checked={!!props.value}
                />
              );
            }}
          />
        }
        label={
          <LabelWithTooltip
            labelText="Book as First Available"
            tooltipText="Once confirmed, the driver will have an additional 30 minutes to begin the trip"
          />
        }
      />
    </FormControl>
  );
};

export const FirstAvailableCheckboxComponent = React.memo(
  _FirstAvailableCheckboxComponent,
);
