import {
  Box,
  Button,
  Container,
  Grid,
  Link,
  makeStyles,
  Paper,
  TextField,
  Typography,
  useMediaQuery,
  useTheme,
} from '@material-ui/core';
import { Alert } from '@material-ui/lab';
import {
  validEmail,
  validPhone,
} from '@onwardcare/core/lib/validation/form-validation';
import { useAuth } from '../contexts/auth-context';
import SectionHeader from 'components/SectionHeader';
import { useTracking } from 'lib/analytics/Tracker';
import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { Link as RouterLink, useHistory } from 'react-router-dom';
import ENV from '../constants/Env';

const { apiUrl } = ENV;

const useStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(2),
  },
  submit: {
    margin: theme.spacing(3, 1, 3, 1),
    width: '95%',
  },
  section: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
  },
  main: {
    padding: theme.spacing(3),
    display: 'flex',
  },
  terms: {
    marginLeft: theme.spacing(0.5),
  },
}));

function RegisterComponent({ registerError, onRegister }) {
  const classes = useStyles();
  const { handleSubmit, errors, register } = useForm();

  const submit = data => {
    onRegister(data);
  };

  const theme = useTheme();
  const smallScreen = useMediaQuery(theme.breakpoints.down('sm'));

  return (
    <Container
      maxWidth="md"
      disableGutters={smallScreen}
      className={classes.main}
    >
      <Paper className={classes.root}>
        <Typography component="h1" variant="h4" gutterBottom>
          Account Sign Up
        </Typography>
        <Typography
          component="h2"
          variant="body1"
          className={classes.helpText}
          gutterBottom
        >
          After creating your account you can fill in your rider information and
          then you'll be ready to book a ride.
        </Typography>
        <Typography component="h2" variant="body1" className={classes.helpText}>
          If at any point you have questions, don't hesitate to call us at
          1-800-700-4797.
        </Typography>
        {registerError && (
          <Alert severity="error">
            {registerError === 'unknown'
              ? 'There was an unexpected error creating your account. Please contact Onward at 1-800-700-4797.'
              : registerError}
          </Alert>
        )}

        <form
          className={classes.form}
          noValidate
          onSubmit={handleSubmit(submit)}
        >
          <SectionHeader title="General Information" />
          <Grid container spacing={2} className={classes.section}>
            <Grid item xs={12} sm={6}>
              <TextField
                required
                fullWidth
                id="firstName"
                name="firstName"
                label="First Name"
                defaultValue={null}
                error={errors.firstName}
                helperText={errors.firstName && 'First Name is required'}
                inputRef={register({
                  required: true,
                })}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                required
                id="lastName"
                fullWidth
                name="lastName"
                label="Last Name"
                defaultValue={null}
                error={errors.lastName}
                helperText={errors.lastName && 'Last Name is required'}
                inputRef={register({
                  required: true,
                })}
              />
            </Grid>

            <Grid item xs={12} sm={6}>
              <TextField
                id="phone"
                fullWidth
                required
                name="phone"
                label="Phone"
                defaultValue={null}
                error={errors.phone}
                helperText={errors.phone && 'A valid phone number is required'}
                inputRef={register({
                  required: true,

                  validate: p => !p || p === '' || validPhone(p),
                })}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                fullWidth
                id="accountName"
                name="accountName"
                label="Company Name"
                defaultValue={null}
                inputRef={register({})}
              />
            </Grid>
          </Grid>
          <SectionHeader title="Login Credentials" />

          <Grid container spacing={2} className={classes.section}>
            <Grid item xs={12} sm={6}>
              <TextField
                required
                id="email"
                fullWidth
                name="email"
                label="Email"
                defaultValue={null}
                error={errors.email}
                helperText={errors.email && 'A valid email is required'}
                inputRef={register({
                  validate: e => validEmail(e),
                })}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                required
                id="password"
                fullWidth
                name="password"
                label="Password"
                type="password"
                defaultValue={null}
                error={errors.password}
                helperText={errors.password && 'A password is required'}
                inputRef={register({
                  required: true,
                })}
              />
            </Grid>
          </Grid>
          <Grid container spacing={2} className={classes.section}>
            <Grid item xs={6}>
              <Button
                key="cancelButton"
                component={RouterLink}
                to="/welcome"
                variant="outlined"
                color="secondary"
                className={classes.submit}
              >
                Cancel
              </Button>
            </Grid>
            <Grid item xs={6}>
              <Button
                key="submitButton"
                type="submit"
                variant="contained"
                color="primary"
                className={classes.submit}
              >
                Submit
              </Button>
            </Grid>
            <Grid item xs={12}>
              <Box display="flex" justifyContent="center">
                <Typography variant="caption">
                  {"By clicking next you agree to Onward's "}
                </Typography>
                <Link
                  href="https://onwardrides.com/terms"
                  target="_blank"
                  className={classes.terms}
                >
                  <Typography variant="caption">Terms of Service</Typography>
                </Link>
              </Box>
            </Grid>
          </Grid>
        </form>
      </Paper>
    </Container>
  );
}

export default function StartPage() {
  const [registerError, setRegisterError] = useState(null);

  const history = useHistory();
  let auth = useAuth();
  const tracker = useTracking();

  useEffect(() => {
    tracker.track('Account Registration Viewed');
  }, [tracker]);

  const onSuccess = email => () => {
    tracker.track('Account Registration Succeeded');
    tracker.alias(email);
    history.replace({ pathname: '/' });
  };

  const onFailure = errMsg => {
    tracker.track('Account Registration Error');
    setRegisterError(errMsg || 'unknown');
  };

  let register = async data => {
    const { email, password, firstName, lastName, accountName, phone } = data;

    const resp = await fetch(`${apiUrl}/custodian_auth`, {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        first_name: firstName,
        last_name: lastName,
        account_name: accountName,
        phone,
        email,
        password,
      }),
    });

    if (resp.status === 200) {
      const { data } = await resp.json();
      const expiry = resp.headers.get('expiry') || '';
      const client = resp.headers.get('client') || '';
      const uid = resp.headers.get('uid') || '';
      const accessToken = resp.headers.get('access-token') || '';

      auth.storeAuthHeaders({ expiry, client, id: data.id, uid, accessToken });
      onSuccess(email);
    } else {
      const errMsg =
        resp.status === 422 ? 'This email address is already taken' : null;
      onFailure(errMsg);
    }
  };

  return (
    <RegisterComponent registerError={registerError} onRegister={register} />
  );
}
