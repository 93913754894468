import React from 'react';
import { useQuery } from '@apollo/client';
import { Box, CircularProgress, Typography } from '@material-ui/core';
import { loader } from 'graphql.macro';
import { RideTimeError } from './rideFormComponents/RideTimeHelper/RideTimeError/RideTimeError';

const RIDE_ESTIMATE_QUERY = loader(
  '../../data/queries/RideEstimateQuery.graphql',
);

const RideEstimate = ({
  requestedStartTime,
  requestedEndTime,
  rideType,
  startLocation,
  endLocation,
  transportType,
  weightLbs,
  stairsCount,
  oxygenRequired,
  contactPrecautionsRequired,
  arrivalBufferMinutes,
  isFlexibleRide,
}) => {
  let ride = {
    requestedStartTime: requestedStartTime.toISOString(),
    rideType,
    startLocation,
    endLocation,
    transportType,
    stairsCount,
    weightLbs: weightLbs || 0,
    oxygenRequired,
    contactPrecautionsRequired,
    arrivalBufferMinutes,
    flexibleRide: isFlexibleRide,
  };
  if (ride.rideType === 'roundtrip' && requestedEndTime) {
    ride.requestedEndTime = requestedEndTime?.toISOString();
  }

  const { loading, error, data } = useQuery(RIDE_ESTIMATE_QUERY, {
    variables: { ride },
  });

  if (loading) return <CircularProgress size={24} color="secondary" />;
  if (error)
    return (
      <RideTimeError
        error=" There was an error generating your estimate. Please contact Onward at
  1-800-700-4797."
      />
    );

  if (data.rideEstimate.estimatedCostFormatted === 'N/A') return null;

  return (
    <Typography component="span" variant="body1">
      <Box display="flex">
        <Box flexGrow={1} fontWeight="fontWeightMedium">
          Fare Estimate:
        </Box>
        <Box fontWeight="fontWeightMedium">
          {data.rideEstimate.estimatedCostFormatted}
        </Box>
      </Box>
      {transportType === 'companion' && data.rideEstimate.estimatedEndTime ? (
        <Box display="flex">
          <Box flexGrow={1} fontWeight="fontWeightMedium">
            End Time:
          </Box>
          <Box fontWeight="fontWeightMedium">
            {data.rideEstimate.estimatedEndTime}
          </Box>
        </Box>
      ) : null}
    </Typography>
  );
};

export default RideEstimate;