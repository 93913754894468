import React from 'react';
import { Control, Controller, FieldErrors } from 'react-hook-form';
import {
  validPhone,
  normalizePhone,
} from '@onwardcare/core/lib/validation/form-validation';
import onwardColors from '../../../../lib/onwardColors';
import {
  Box,
  FormHelperText,
  makeStyles,
  TextField,
  Typography,
} from '@material-ui/core';

type Props = {
  control: Control;
  disabled: boolean;
  errors: FieldErrors<any>;
};

const useStyles = makeStyles(theme => ({
  fareSection: {
    margin: theme.spacing(1),
    padding: theme.spacing(1),
    width: '95%',
    borderLeftColor: onwardColors.onwardBlue,
    borderLeftWidth: 3,
    borderLeftStyle: 'solid',
    paddingLeft: '15px',
  },
  formControl: {
    margin: theme.spacing(1),
    width: '95%',
  },
  heightContainer: {
    marginTop: theme.spacing(2),
  },
}));

export const BedSideNurseComponent: React.FC<Props> = ({
  control,
  disabled,
  errors,
}) => {
  const classes = useStyles();

  return (
    <Box className={classes.fareSection}>
      <Typography variant="body1">Bedside Nurse Form</Typography>
      <Controller
        name={'bedsideNurse.fullName'}
        control={control}
        rules={{ required: true }}
        render={props => {
          return (
            <TextField
              error={
                // @ts-ignore
                !!errors?.bedsideNurse?.fullName
              }
              helperText={
                !!(
                  // @ts-ignore
                  errors?.bedsideNurse?.fullName
                ) ? (
                  <FormHelperText error>Name is required</FormHelperText>
                ) : null
              }
              value={props.value}
              fullWidth
              disabled={disabled}
              label="Nurse Full Name"
              onChange={e => {
                props.onChange(e.target.value);
              }}
            />
          );
        }}
      />
      <Controller
        name={'bedsideNurse.phone'}
        control={control}
        rules={{
          required: true,
          validate: (value: string) => {
            const onlyNumbers = normalizePhone(value);

            if (!onlyNumbers) {
              return false;
            }

            return validPhone(
              value
                //Non-Breaking Hyphen
                .replaceAll('\u2011', '-')
                //Non-Breaking Space
                .replaceAll('U+00A0', ' ')
                .replaceAll(/\s+/g, ''),
            );
          },
        }}
        render={props => {
          return (
            <TextField
              error={
                // @ts-ignore
                !!errors?.bedsideNurse?.phone
              }
              helperText={
                (errors?.bedsideNurse?.phone?.type === 'required' && (
                  <FormHelperText error>Phone is required</FormHelperText>
                )) ||
                //@ts-ignore
                (errors?.bedsideNurse?.phone?.type === 'validate' && (
                  <FormHelperText error>Phone is incorrect</FormHelperText>
                ))
              }
              value={props.value}
              fullWidth
              disabled={disabled}
              label="Nurse Phone"
              onChange={props.onChange}
            />
          );
        }}
      />
    </Box>
  );
};
