import {
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  Link,
  Typography,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import moment from 'moment-timezone';
import PhoneIcon from '@material-ui/icons/Phone';
import {
  firstLastInitial,
  vehicleDescription,
} from '@onwardcare/core/lib/utils/name-utils';
import { formatPhoneNumber } from '@onwardcare/core/lib/utils/phone-utils';
import PersonImage from 'components/PersonImage';
import VehicleImage from 'components/VehicleImage';
import ZeroStateText from 'components/ZeroStateText';
import ShowMoreText from 'react-show-more-text';

const useStyles = makeStyles(theme => ({
  personSection: {
    marginTop: theme.spacing(2),
    height: '100%',
  },
  vehicleDescription: {
    lineHeight: '1.25',
  },
  cardIcon: {
    marginRight: theme.spacing(1),
  },
  avatar: {
    marginRight: theme.spacing(1),
  },
  license: {
    textAlign: 'center',
  },
  bio: {
    marginTop: theme.spacing(1),
  },
  smallBox: {
    height: '100%',
  },
}));

function rideIsInWindowToShowDriverPhone(ride) {
  const rideStartsInNextDay = moment().isAfter(
    moment(ride.requestedStartTime).subtract(36, 'hour'),
  );
  const rideCompletedWithinAnHour = moment().isBefore(
    moment(ride.actualEndTime).add(1, 'hour'),
  );

  return (
    ride.status === 'inprogress' ||
    (ride.status === 'completed' && rideCompletedWithinAnHour) ||
    (ride.status === 'confirmed' && rideStartsInNextDay)
  );
}

export default function DriverCard({ ride }) {
  const classes = useStyles();

  const renderDriverDetails = () => {
    const { driver } = ride;

    if (!driver || driver.isPlaceholder) {
      return (
        <ZeroStateText>
          Driver details will be provided closer to the ride start time.
        </ZeroStateText>
      );
    }

    const { currentVehicle } = driver;

    return (
      <>
        <Box display="flex">
          <Box className={classes.avatar}>
            <PersonImage person={driver} size="large" />
          </Box>
          <Box flexGrow={1}>
            <Typography variant="h6">{firstLastInitial(driver)}</Typography>
            <Typography
              variant="subtitle2"
              className={classes.vehicleDescription}
            >
              {vehicleDescription(currentVehicle)}
            </Typography>
          </Box>
          <Box>
            {currentVehicle && <VehicleImage vehicle={currentVehicle} />}
            {currentVehicle && (
              <Typography variant="subtitle2" className={classes.license}>
                {currentVehicle.licensePlate}
              </Typography>
            )}
          </Box>
        </Box>
        <Box className={classes.bio}>
          {driver.bio && (
            <ShowMoreText lines={5} keepNewLines>
              {driver.bio}
            </ShowMoreText>
          )}
        </Box>
      </>
    );
  };

  const renderDriverActions = () => {
    if (ride.driver?.twilioPhone && rideIsInWindowToShowDriverPhone(ride)) {
      return (
        <Button
          component={Link}
          href={'tel:' + ride.driver.twilioPhone}
          color="secondary"
          variant="contained"
          startIcon={<PhoneIcon />}
        >
          {formatPhoneNumber(ride.driver.twilioPhone)}
        </Button>
      );
    }
  };

  return (
    <Card className={classes.personSection}>
      <CardHeader title="Driver" action={renderDriverActions()} />
      <CardContent>{renderDriverDetails()}</CardContent>
      {/* <CardActions>{renderDriverActions()}</CardActions> */}
    </Card>
  );
}
