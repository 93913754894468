import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from '@material-ui/core';
import { memo } from 'react';
import moment from 'moment-timezone';
import { Ride } from '../../../generated/graphql';
import { getBestTimeText } from '../../common/BestTimeMessages';

export type Props = {
  setOpenBestTimeModal: (value: boolean) => void;
  openBestTimeModal: boolean;
  handleDeclineBestTime?: () => void;
  ride: Ride;
};

const _BestTimeDialog: React.FC<Props> = ({
  setOpenBestTimeModal,
  openBestTimeModal,
  handleDeclineBestTime,
  ride,
}) => {
  if (!openBestTimeModal) {
    return null;
  }
  const {
    bidRequestedStartTime,
    biddingWindowEndsAt,
    bestTimeBidsDeclineBefore,
  } = ride;

  const hasBestTimeWinningBid =
    !!bidRequestedStartTime && biddingWindowEndsAt === null;
  const inBestTimeDeclineWindow =
    bestTimeBidsDeclineBefore &&
    moment().isBefore(moment(bestTimeBidsDeclineBefore));
  const shouldShowDeclineButton =
    hasBestTimeWinningBid && inBestTimeDeclineWindow;

  let bestTimeModalText = getBestTimeText(ride);

  return (
    <Dialog
      maxWidth="xs"
      aria-labelledby="bestTime-dialog-title"
      open={openBestTimeModal}
    >
      <DialogTitle id="bestTime-dialog-title">Best Time Info</DialogTitle>
      <DialogContent>
        <DialogContentText>{bestTimeModalText}</DialogContentText>
      </DialogContent>
      <DialogActions>
        {shouldShowDeclineButton ? (
          <Button
            variant="text"
            onClick={handleDeclineBestTime}
            color="primary"
          >
            decline Best Time Bid
          </Button>
        ) : null}
        <Button
          variant="contained"
          onClick={() => setOpenBestTimeModal(false)}
          color="primary"
        >
          Ok
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export const BestTimeDialog = memo(_BestTimeDialog);
