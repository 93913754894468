import React from "react";
import { Box } from "@material-ui/core";
import { RideshareComponentProps } from "./types";
import { FirstAvailableCheckboxComponent } from './FirstAvailableCheckboxComponent';
import { FlexibleRideCheckboxComponent } from './FlexibleRideCheckboxComponent';
import useStyles from "./styles";
import { useSessionContext } from "../../../../contexts/session-context";

const _RideshareComponents: React.FC<RideshareComponentProps> = ({
  control,
  isUpdate,
  showRecurringOptions,
  firstAvailableEnabled,
  isFlexibleRide,
  isFirstAvailable,
}) => {
  const { session } = useSessionContext();
  const classes = useStyles();
  const firstAvailableDisabled = isUpdate || showRecurringOptions || isFlexibleRide;
  const flexibleRideEnabled = session?.featureFlags?.includes('flexible_ride');

  if (!firstAvailableEnabled && !flexibleRideEnabled) {
    return null;
  }

  return (
    <Box className={classes.fareSection}>
      {firstAvailableEnabled && (
        <FirstAvailableCheckboxComponent
          control={control}
          disabled={firstAvailableDisabled}
        />
      )}
      {flexibleRideEnabled && (
        <FlexibleRideCheckboxComponent
          control={control}
          disabled={isFirstAvailable}
      />
      )}
    </Box>
  );
};

export const RideshareComponents = React.memo(
  _RideshareComponents,
);

