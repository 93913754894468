import React, { useCallback, useEffect, useRef, useState } from 'react';
import makeStyles from '@material-ui/core/styles/makeStyles';
import onwardPin from '../images/logo192.png';
import Avatar from '@material-ui/core/Avatar/Avatar';
import onwardColors from '../lib/onwardColors';
import {
  Box,
  Button,
  Container,
  FormControl,
  FormHelperText,
  FormLabel,
  Grid,
  TextField,
  Typography,
} from '@material-ui/core';
import { useParams } from 'react-router-dom';
import ENV from '../constants/Env';
import { StripeForm } from '../components/layout/StripeForm';
import { loadStripe, Stripe, StripeElementsOptions } from '@stripe/stripe-js';
import { Location, StripeSetupIntentQueryQuery } from '../generated/graphql';
import { Elements } from '@stripe/react-stripe-js';
import { Controller, useForm } from 'react-hook-form';
import LocationRow from '../components/LocationRow';
import { centsToFormattedDollars } from '@onwardcare/core/lib/utils/money-utils';
import moment from 'moment';

const flattenObj = (ob: any) => {
  // The object which contains the
  // final result
  let result = {};

  // loop through the object "ob"
  for (const i in ob) {
    // We check the type of the i using
    // typeof() function and recursively
    // call the function again
    if (typeof ob[i] === 'object' && !Array.isArray(ob[i])) {
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      const temp = flattenObj(ob[i]);
      for (const j in temp) {
        // Store temp in result
        // @ts-ignore
        result[i.split('_').join(' ') + ' ' + j.split('_').join(' ')] = temp[j];
      }
    }

    // Else store ob[i] in result directly
    else {
      // @ts-ignore
      result[i] = ob[i];
    }
  }
  return result;
};

const getPaymentsMethods = async (slug: string) => {
  return await fetch(`${ENV.apiUrl}/api/rides/${slug}/payment_methods/new/`, {
    headers: {
      Accept: 'application/json',
    },
  });
};

const sendContacts = async (slug: string, email: string, phone: string) => {
  return await fetch(`${ENV.apiUrl}/api/rides/${slug}/payer`, {
    method: 'POST',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({ email, phone }),
  });
};

const SOMETHING_WENT_WRONG_TEXT =
  'Something went wrong, please try again later.';
const THANK_YOU_TEXT = 'Thank you for using Onward.';
const CARD_ADDED_TEXT =
  'Thank you for providing your payment details, the trip will be charged after completion and a receipt will be sent to the contact provided.';

type FormContacts = {
  email: string;
  phone: string;
};

type BodyResponse = {
  ride: {
    requested_start_time: string;
    transport_type: {
      display_name: string;
    };
    start_location: {
      name: string;
      address: string;
    };
    end_location: {
      name: string;
      address: string;
    };
    estimated_cost: number;
  };
  custodian: {
    display_name: string;
  };
  account: {
    display_name: string;
  };
  rider: {
    display_name: string;
  };
  payment_method: {
    display_name: string | null;
  };
};

export const StripeAddPage = () => {
  const styles = useStyles();
  const [error, setError] = useState('');
  const [endResult, setEndResult] = useState('');
  const [shouldSendContacts, setShouldSendContacts] = useState(false);
  const [buttonEnable, setButtonEnable] = useState(false);

  const defaultValues = {
    email: '',
    phone: '',
  };

  const {
    errors,
    control,
    watch,
    handleSubmit,
    setValue,
  } = useForm<FormContacts>({
    defaultValues,
  });

  const watchEmail = watch('email');
  const watchPhone = watch('phone');

  const stripeBtnRef = useRef<HTMLButtonElement | null>(null);

  const { slug } = useParams<{ slug: string }>();
  const [
    stripeData,
    setStripeData,
  ] = useState<StripeSetupIntentQueryQuery | null>(null);
  const [
    stripePromise,
    setStripePromise,
  ] = useState<Promise<Stripe | null> | null>(null);
  const [
    stripeOptions,
    setStripeOptions,
  ] = useState<StripeElementsOptions | null>(null);

  const [headerText, setHeader] = useState('');
  const [bodyText, setBodyText] = useState<string | null>('');

  const getSetupIntent = useCallback(
    () =>
      getPaymentsMethods(slug)
        .then(result => result.json())
        .then(data => {
          if (data.status > 400) {
            setHeader(SOMETHING_WENT_WRONG_TEXT);
            return;
          }
          if (data.success && data.message?.stripe) {
            const header = data.message?.page?.header;
            const body = data.message?.page?.body as BodyResponse | undefined;
            header && setHeader(header);
            if (body) {
              let bodyContent = null;

              const startTime = moment(body.ride.requested_start_time).format(
                'MMMM Do, h:mm A',
              );

              const StartTime = (
                <Box
                  display="flex"
                  alignItems="center"
                  justifyContent="space-between"
                >
                  <Typography>Start Time:</Typography>
                  <Typography>{startTime}</Typography>
                </Box>
              );

              const startLocation: Location = {
                name: body.ride.start_location.name,
                address: body.ride.start_location.address,
                id: '1',
              };

              const StartLocation = (
                <LocationRow
                  location={startLocation}
                  showAddress
                  showInstructions={false}
                />
              );

              const endLocation: Location = {
                name: body.ride.end_location.name,
                address: body.ride.end_location.address,
                id: '1',
              };

              const EndLocation = (
                <LocationRow
                  showInstructions={false}
                  showAddress
                  location={endLocation}
                />
              );

              const TransportType = (
                <Box
                  display="flex"
                  alignItems="center"
                  justifyContent="space-between"
                >
                  <Typography>Transport Type:</Typography>
                  <Typography>
                    {body.ride.transport_type.display_name}
                  </Typography>
                </Box>
              );

              const Coordinator = (
                <Box
                  display="flex"
                  alignItems="center"
                  justifyContent="space-between"
                >
                  <Typography>Coordinator:</Typography>
                  <Typography>{body.custodian.display_name}</Typography>
                </Box>
              );

              const Account = (
                <Box
                  display="flex"
                  alignItems="center"
                  justifyContent="space-between"
                >
                  <Typography>Account:</Typography>
                  <Typography>{body.account.display_name}</Typography>
                </Box>
              );

              const Rider = (
                <Box
                  display="flex"
                  alignItems="center"
                  justifyContent="space-between"
                >
                  <Typography>Rider</Typography>
                  <Typography>{body.rider.display_name}</Typography>
                </Box>
              );

              const Estimated =
                body.ride.estimated_cost !== undefined ? (
                  <Box
                    display="flex"
                    justifyContent="space-between"
                    alignItems="center"
                  >
                    <Typography>Estimate:</Typography>
                    <Typography>
                      {centsToFormattedDollars(body.ride.estimated_cost)}
                    </Typography>
                  </Box>
                ) : null;

              const Payment = body.payment_method.display_name ? (
                <>
                  <Box
                    display="flex"
                    justifyContent="space-between"
                    alignItems="center"
                  >
                    <Typography>Current Payment Method:</Typography>
                    <Typography>{body.payment_method.display_name}</Typography>
                  </Box>
                  <Box
                    display="flex"
                    justifyContent="space-between"
                    alignItems="center"
                    className={styles.fieldComment}
                  >
                    <Typography>
                      you can add a different payment method:
                    </Typography>
                  </Box>
                </>
              ) : null;

              bodyContent = (
                <Box>
                  {StartLocation}
                  {EndLocation}
                  {StartTime}
                  {TransportType}
                  {Coordinator}
                  {Account}
                  {Rider}
                  {Estimated}
                  {Payment}
                </Box>
              );

              // @ts-ignore
              setBodyText(bodyContent);
            }

            setStripeData({
              stripeSetupIntent: {
                ...data?.message.stripe,
              },
            });
            data?.message?.stripe?.publishableKey &&
              setStripePromise(
                loadStripe(data?.message?.stripe?.publishableKey),
              );
            setStripeOptions({
              locale: 'en',
              clientSecret: data?.message?.stripe?.setupIntent,
              appearance: {
                theme: 'flat',
                variables: {
                  borderRadius: '0px',
                },
              },
            });
            if (data?.message?.payer) {
              const email = data?.message?.payer.email;
              const phone = data?.message?.payer.phone;
              setTimeout(() => {
                email && setValue('email', email);
                phone && setValue('phone', phone);
              });
            }
          }
        })
        .catch(e => {
          setHeader(SOMETHING_WENT_WRONG_TEXT);
        }),
    [slug],
  );

  useEffect(() => {
    getSetupIntent();
  }, []);

  const submitForm = (data: FormContacts) => {
    if (stripeBtnRef.current) {
      stripeBtnRef.current.click();
    }
  };

  const getAccount: any = () => {
    setShouldSendContacts(true);
  };

  useEffect(() => {
    const sendForm = async () => {
      const result = await sendContacts(slug, watchEmail, watchPhone);
      if (result.status >= 400) {
        setError('Something went wrong, try again later');
      } else {
        setEndResult(CARD_ADDED_TEXT);
      }
    };

    if (shouldSendContacts) {
      setShouldSendContacts(false);
      sendForm();
    }
  }, [shouldSendContacts, slug, watchEmail, watchPhone]);

  const setStripeButtonRef = (btnRef: HTMLButtonElement) => {
    stripeBtnRef.current = btnRef;
  };

  return (
    <section className={styles.fullContainer}>
      <Avatar alt="Onward Pin" className={styles.avatar} src={onwardPin} />
      <Typography component="h1" variant="h4" className={styles.title}>
        Onward
      </Typography>

      <Container maxWidth="md">
        <Box
          display="flex"
          justifyContent="center"
          alignItems="center"
          className={styles.content}
        >
          <div className={styles.divider} />
          {stripePromise && stripeOptions && !endResult ? (
            <div className={styles.cardStyle}>
              <div>
                {headerText && (
                  <Typography align="center" variant="h6">
                    {headerText}
                  </Typography>
                )}
                {bodyText}
              </div>
              <div className={styles.divider} />
              <Elements stripe={stripePromise} options={stripeOptions}>
                <StripeForm
                  stripeData={stripeData}
                  getSetupIntent={getSetupIntent}
                  getAccount={getAccount}
                  ownerType="Account"
                  setButtonRef={setStripeButtonRef}
                  shouldShowAddButton={false}
                  setFormReadyCb={() => {
                    setButtonEnable(true);
                  }}
                  shouldGetSetupIntent={false}
                />
              </Elements>
            </div>
          ) : (
            <div>
              {endResult && <Typography variant="h4">{endResult}</Typography>}
              {endResult && (
                <Typography variant="h4">{THANK_YOU_TEXT}</Typography>
              )}
            </div>
          )}
          {!stripePromise && !stripeOptions && (headerText || bodyText) ? (
            <div className={styles.centered}>
              {headerText && <Typography variant="h3">{headerText}</Typography>}
              {bodyText}
            </div>
          ) : null}

          {stripePromise && stripeOptions && !endResult ? (
            <form onSubmit={handleSubmit(submitForm)}>
              <FormControl component="fieldset" className={styles.formControl}>
                <FormLabel>Contact Information for Trip Receipt</FormLabel>
                <Grid container spacing={1}>
                  <Grid item xs={12}>
                    <Controller
                      name="email"
                      control={control}
                      rules={{ required: true }}
                      render={props => {
                        return (
                          <>
                            <TextField
                              id="email"
                              fullWidth
                              label={<span>Email</span>}
                              value={props.value}
                              error={!!errors.email}
                              helperText={
                                errors.email ? 'Email is required' : null
                              }
                              onChange={e => props.onChange(e.target.value)}
                            />
                          </>
                        );
                      }}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <Controller
                      rules={{ required: true }}
                      name="phone"
                      control={control}
                      render={props => {
                        return (
                          <TextField
                            id="phone"
                            error={!!errors.phone}
                            label={<span>Phone</span>}
                            value={props.value}
                            helperText={
                              errors.phone ? 'Phone is required' : null
                            }
                            fullWidth
                            onChange={e => props.onChange(e.target.value)}
                          />
                        );
                      }}
                    />
                  </Grid>
                </Grid>

                {error ? (
                  <FormHelperText className={styles.errorText} error>
                    {error}
                  </FormHelperText>
                ) : null}
                <Button
                  key="submitButton"
                  variant="contained"
                  color="primary"
                  type="submit"
                  className={styles.submitButton}
                  disabled={!buttonEnable}
                >
                  Submit
                </Button>
              </FormControl>
            </form>
          ) : null}
        </Box>
      </Container>
    </section>
  );
};

const useStyles = makeStyles(theme => ({
  title: {
    color: onwardColors.onwardMedPurple,
    fontWeight: 700,
  },
  fullContainer: {
    height: '100vh',
    width: '100vw',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    paddingTop: theme.spacing(3),
  },
  avatar: {
    width: theme.spacing(8),
    height: theme.spacing(8),
  },
  centered: {
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
  },
  icon: {
    fontSize: 48,
  },
  content: {
    flexDirection: 'column',
  },
  divider: {
    marginTop: theme.spacing(3),
  },
  fieldComment: {
    color: onwardColors.onwardLightGray,
    fontSize: '0.7rem',
    fontStyle: 'italic',
  },
  button: {
    alignSelf: 'flex-end',
  },
  cardStyle: {
    width: '50%',
  },
  actionButton: {
    marginRight: theme.spacing(2),
  },
  formControl: {
    width: 450,
    marginTop: theme.spacing(4),
  },
  submitButton: {
    marginTop: theme.spacing(2),
    alignSelf: 'flex-end',
  },
  errorText: {
    fontSize: '1.4rem',
  },
}));
